import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComp from './components/NavbarComp';
import { HelmetProvider } from 'react-helmet-async';

function App(props) {
  return (
    <div className="App">
      <HelmetProvider>
        <NavbarComp />
      </HelmetProvider>
    </div>
  );
}

export default App;
