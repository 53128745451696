import React, { Component } from 'react'

import mission_alkalam from "../assets/mission_alkalam.png" 
import inspire from "../assets/inspire.png"
import inspiration_image from "../assets/inspiration_image.png"
import inspiration from "../assets/inspiration.png"
import inspire_text from "../assets/inspire_text.png"
import empowerment from "../assets/empowerment.png"
import the_sciences from "../assets/the_sciences.png" 
import mastery from "../assets/mastery.png"
import integrated_sciences from "../assets/integrated_sciences.png"
import innovation from "../assets/innovation.png"
import innovate from "../assets/innovate_image.png"
import innovation_text from "../assets/innovation_text.png"


export default class About extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "About"
    }

    render() {
        return (
            <div className="webpage_content">
                <br /><br /><br /><br /><br />
                <img classname="article_images" className="article_images" src={mission_alkalam} alt="Alkalam Institute" width="85%" height="85%"/>
                <br /><br />
                <img align="left" className="article_images" src={inspiration} alt="Alkalam Institute" width="43%" height="43%"/>
                <img className="article_images" src={inspire_text} alt="Alkalam Institute" width="85%" height="85%" />
                <br /><br />
                <img classname="article_images" src={inspiration_image} alt="Alkalam Institute" width="85%" height="85%"/>
                <br /><br /><br />
                <img align="left" className="article_images" src={empowerment} alt="Alkalam Institute" width="43%" height="43%"/>
                <img classname="article_images" src={the_sciences} alt="Alkalam Institute" width="90%" height="90%" />
                <br /><br />
                <img classname="article_images" src={mastery} alt="Alkalam Institute" width="85%" height="85%"/>
                <img classname="article_images" src={integrated_sciences} alt="Alkalam Institute" width="85%" height="85%"/>
                <img align="left" className="article_images" src={innovation} alt="Alkalam Institute" width="43%" height="43%"/>
                <br /><br /><br /><br /> 
                <img classname="article_images" src={innovation_text} alt="Alkalam Institute" width="95%" height="95%" />
                <br /><br /><br /><br /> 
                <img classname="article_images" src={innovate} alt="Alkalam Institute" width="95%" height="95%" />
                <br /><br /><br /><br /><br /><br />    
            </div>
        )
    }
}
