import React, { Component } from 'react'

export default class Consciousness extends Component {

    constructor(props) {
        super(props);
        this.state = {
            like: 0,
            dislike: 0
        }
    }

    likeIncrement = () => {
        this.setState({ like: this.state.like + 1})
    }

    dislikeIncrement = () => {
        this.setState({ dislike: this.state.like + 1})
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Consciousness, The Closest Proof of God"
    }

    render() {
        return (
            <div className="webpage_content">
                <div className="like_dislike">
                    <div className='like'>
                        <input onClick={this.likeIncrement} type="image" src="../../../assets/article_dislike1.png" alt="dislike" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.like}</div> */}
                    <div className='dislike'>
                        <input onClick={this.dislikeIncrement} type="image" src="../../../assets/article_like1.png" alt="like" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.dislike}</div> */}
                </div>

                <img className="poster" src="../../../assets/sb_con.jpg" alt="Alkalam Research Institute (ARI)"/>
                
                <h3><br/>Consciousness, The Closest Proof of God</h3>
                <p className="paragraph"><br />The earliest English language use of the word "consciousness", with its modern meaning, dates back to the late 1600s. It was John Locke who defined it as “the perception of what passes in a man's own mind” [1]. Whether his definition was complete or accurate is contestable. Despite its late appearance in English literature, the manifest, yet complicated concept of consciousness, at times referred to by different names, has been a part of religious and philosophical discourse for much longer. It should come as no surprise that Islam, the fastest-growing religion in the world, also has many references to consciousness – both direct and indirect. Islam, however, does not try to explain or define consciousness. Instead, it expands upon what it proves. Being guided by the teachings of Islam, this paper, therefore, will not attempt to define conciousness, but rather shed light on how it proves the existence of God.</ p>
                <p className="paragraph">In establishing consciousness as evidence of God’s existence, a layered approach will be taken. We will first assert the concreteness of human existence by proving that thought cannot exist detached from the externally existent thinker. We will then provide support for the position that the conceptualization of existence is a priori and that we, therefore, not only exist but are aware of our existence. This then requires us to answer another question, which is “when do we become conscious beings?”. To answer this question from an Islamic perspective, the words used for consciousness in the Quran and Hadith will first be highlighted. Once these fundamentals are addressed, the main argument of this paper, which is that consciousness is the closest proof of God, will be presented through Quranic verses, the sayings of Prophet Muhammad (pbuh), and the sayings of the scholars of Tasawwuf.</p>
                <h4>Establishing Our Existence</h4>
                <p className="paragraph"><br />Before answering the question “to be or not to be?” a more fundamental question needs to be answered, which is “do I exist or do I not?”. Or does it? Doesn’t the question “to be or not to be'' already imply that the questioner exists? A group of philosophers who were referred to as al-I’nadia (the obstinate sophists) by Muslim theologians would argue that it does not. They claimed that there is no reality since everything is an illusion. Thus, to them, before asking the question “to be or not be?” one’s existence needed to be established. Regarding this group of sophists, Imam al-Taftazani says:<br /></p>
                <p className="paragraph_arabic">خلافا للسوفسطائية، فإن منهم من ينكر حقائق الأشياء و يزعم أنها أوهام و خيالات موهومات و هم العنادية</p>
                <p className="paragraph"><i>“In contradiction to the Sophists l (al'Sufasta'iya), for some of them deny the real essences of things and maintain that they are fancies (awham) and vain imaginations (khayalat). These are the Obstinate (al-inadiya).” [2]</i></p>
                {/* <div margin-right="15px"><Image src="../../assets/first.png" responsive/></div> */}
                <p className="paragraph">But if what they said is true, who’s illusion and imagination is it? It remains unclear whether they ever figured out the answer to this question. Based on what we know of their teachings, it’s unlikely they would have been capable of doing so since they denied the essence of all things. Therefore, if they claimed it is their imagination, they would be contradicting themselves as they also had an essence. Imam al-Farhari states in <i>al-Nibras</i>:</p>
                {/* <div margin-right="15px"><Image src="../../assets/second.png" responsive/></div> */}
                <p className="paragraph_arabic">و لذا قال المحققون ليس إنكارهم مقصورا على الموجودات الخارجة بل ينكرون كل ما في نفس الأمر سواء كان خارجيا أو ذهنيا</p>
                <p className="paragraph"><i>“Their rejection is not limited to external beings, but rather they reject all things that exist, be they external or mental.” [3]</i></p>
                <p className="paragraph">One understands from this that, according to their teachings, their perception and image of themselves would also be an imagination. In other words, they did not hold the solipsistic epistemological position.</p>
                <p className="paragraph">Even if for the sake of argument, we submit to their claim and agree that everything is an imagination, it is clear that it must be someone’s. Therefore, the question “do I exist or do I not” is posed by that same someone. Moreover, if their argument was that there are an infinite number of imagined persons in the past (meaning one imagined being is imagined by another who is imagined by another and so forth), thus, never reaching an existing being with the ability to pose questions, it is countered by the fact that infinite regress (<i>tasalsul</i>) is impossible and that an imagination, regardless of how many layers it has, requires an imaginer whose existence is external.</p>
                <p className="paragraph">The only way “do I exist or do I not?” would be a meaningful question, is if it is asked by those who are imagined. But if asked by the imagined, then what would Descartes say? Will his conclusion that we cannot doubt our existence while we doubt remain true? Or should it be dismissed? According to him, we cannot doubt our existence while in a state of doubt because doubt cannot originate from nonexistent beings. In his own words, “Je pense, donc je suis”, which means “I think, therefore I am” [4]. Descartes’ belief would still hold because the question asked by the imagined is not a conscious one but one that is imposed on him or her by the imaginer. Therefore, in reality, it is not the imagined that is doubting as the imagined does not have the faculties from which doubt is born. We can then conclude that since the imagined is not really doubting, the lack of his or her external existence is possible. </p>
                <p className="paragraph">But this means our existence may not be real and external. What if doubt is imposed upon us? What if we are the imagined and not the imaginer? To answer this question, we can no longer rely on reason, as reason does not deem this impossible. We have to find the answer to whether we are imagined through experience. That is why this writer attempted to enter the realm of his own imagination to witness its inner workings and determine its limitations.</p>
                <p className="paragraph">His imagination, set in a large barren landscape, occupied by only imagined beings led him to the following conclusion. After multiple attempts, he realized that he can only make the imagination of his own self  doubt its existence. All of the other imagined beings with him in the desert were merely acting how he was imagining them to act. He could make them outwardly confused, behaving as if they were not sure if they exist or not, yet that was nothing but a mere outward act. So he realized if he is an imagination, he is his own imagination because he is able to doubt. Therefore, if every single one of us is an imagination, even if not in this realm, we still have an external existence. This perfectly reflects our relationship with our dreams. The experiment conducted can be performed by anyone. All that is needed is a certain level of concentration and the imagination of one’s self, in first person, with a group of people. </p>
                <p className="paragraph">An interesting observation from the experiment was being able to imagine oneself only with the internal traits and characteristics one possesses, including one's gender. This fact is also evident in the dreams that we have. Therefore, the argument that we are all the imagination of the same person who is switching characters so fast that it becomes unnoticeable is invalid. The only other possibility is that the being in whose imagination we exist is not human and is so powerful that he is able to grant all of us the ability to doubt and be aware. In which case it would no longer be an imagination, but rather something only that powerful being possesses. To the monotheists that powerful being is God.</p>
                <p className="paragraph">This also addresses the confusion caused by the simulation hypothesis if, by simulation, its proponents mean we are all artificial simulations being controlled by real external beings outside the simulation. If we are a simulation, we must be our own for the same reasons described above. Thus, we exist even if that physical existence is not in this simulation.</p>
                <p className="paragraph">However, Nick Bostrom, who pioneered the current form of the simulation hypothesis, goes a step further and speaks of consciousness originating in silicon-made brains, giving rise to purely virtual conscious simulated beings. In his paper, <i>Are You Living in a Computer Simulation?</i>, he states the following:</p>
                <p className="paragraph"><i>“Suppose that these simulated people are conscious (as they would be if the simulations were sufficiently fine-grained and if a certain quite widely accepted position in the philosophy of mind is correct). Then it could be the case that the vast majority of minds like ours do not belong to the original race but rather to people simulated by the advanced descendants of an original race.” [5]</i></p>
                <p className="paragraph">There are numerous flaws with this hypothesis that make addressing it a social necessity, not an intellectual one. The first and main flaw stems from dismissing the fact that consciousness has not ceased to be a mystery which no one claims to fully understand let alone create. There are no direct correlations between how real a video game can get through our technological advancement and our understanding of consciousness. </p>
                <p className="paragraph">On a more granular level, Bostrom himself admits that the simulation hypothesis would make sense and be possible only if a certain controversial position in the philosophy of mind is true. That position is the following:</p>
                <p className="paragraph"><i>“A common assumption in the philosophy of mind is that of substrate-independence. The idea is that mental states can supervene on any of a broad class of physical substrates. Provided a system implements the right sort of computational structures and processes, it can be associated with conscious experiences.” [5]</i></p>
                <p className="paragraph">Before going any further, it must be noted here that the hypotheses regarding the properties of mental states are still being debated in the philosophy of mind. This shows that the glue which keeps the substrate-independence position together has not proven to be adhesive yet.</p>
                <p className="paragraph">Even if we assume that mental states are an accurate depiction of how our brains work and that we also know everything about them, there are still other problems with substrate-independence. Another weaknesses of this position is highlighted by the philosopher Paul Thagard who states:</p>
                <p className="paragraph"><i>“Substrate independence and mind-body functionalism claim that thinking does not depend on any particular kind of physical implementation. But real-world information processing depends on energy and energy depends on material substrates. Biological evidence for these claims comes from ecology and neuroscience, while computational evidence comes from neuromorphic computing and deep learning. Attention to energy requirements undermines the use of substrate independence to support claims about the feasibility of artificial intelligence, the moral standing of robots, the possibility that we may be living in a computer simulation, the plausibility of transferring minds into computers, and the autonomy of psychology from neuroscience.”</i> [6]</p>
                <p className="paragraph">To be able to reveal the other flaws of this hypothesis, let us accept this position as a fact. If we do, we will soon realize that it still does not provide a solution for how we introduce the first spark of consciousness. The “fact” that mental states can supervene on any class of physical substrates does not indicate that the mental states’ required spark of awareness can be born from any physical substrates. To claim we will grant consciousness to machines if we build silicon brains on which mental states can supervene is similar to saying we will make a person talk if we can make sure their tongue can move. We are forgetting that not all phenomena are the cause, most are either conditions or effects. In our example, the ability to move one's tongue is a condition for speech, talking is the effect, and a person’s will is the cause.</p>
                <p className="paragraph">If we are relying on “the right sort of computational structures and processes” to introduce the first few mental states and make them supervene in the hopes that the supervening of mental states gives birth to consciousness, we are putting ourselves in a circular fallacy. The concept of mental states, regardless of how vague it may be, assumes awareness, as the two types of mental states are conscious mental states and unconscious mental states. Moreover, the unconscious mental states are products of conscious ones. With that in mind, how are we planning to introduce this first conscious mental state if we are relying on the supervening of mental states to give birth to consciousness?</p>
                <p className="paragraph">On the other hand, if we are relying on “the right sort of computational structures and processes” to implement Artificial Intelligence and learn so fast that they turn into supervening mental states (with the assumption that mental states are detached from consciousness) which will then give birth to consciousness, we are essentially attempting to shake a dead body until blood starts circulating in the veins in the hopes of giving it life.</p>
                <p className="paragraph">Lastly, if we are relying on “the right sort of computational structures and processes” to introduce the first conscious mental states, any computer scientist will tell you that structures and processes are nothing but instructions given to computers by human beings. Regardless of how complicated and layered those instructions may be, they are still nothing but instructions. How those instructions, implemented by computers, turn into consciousness is not addressed by Bostrom.</p>
                <p className="paragraph">Here is where consciousness is born in this hypothesis:</p>
                <p className="paragraph"><i>“Provided a system implements the right sort of computational structures and processes, it can be associated with conscious experiences.” … “For example, we need not assume that the thesis of substrate-independence is necessarily true (either analytically or metaphysically) – just that, in fact, a computer running a suitable program would be conscious.”</i> [5]</p>
                <p className="paragraph">Thus, it appears there is an enormous assumption being made. If by consciousness the proponents of this hypothesis mean the ability to execute human-like instructions so fast that they become seamless, a different term should be used for this phenomenon as self-awareness is not its concomitant. Bostrom does state that:</p>
                <p className="paragraph"><i>“Moreover, we need not assume that in order to create a mind on a computer it would be sufficient to program it in such a way that it behaves like a human in all situations, including passing the Turing test etc. We need only the weaker assumption that it would suffice for the generation of subjective experiences that the computational processes of a human brain are structurally replicated in suitably fine-grained detail, such as on the level of individual synapses. This attenuated version of substrate-independence is quite widely accepted.”</i> [5]</p>
                <p className="paragraph">Based on the above, the consciousness in question is not the consciousness with which humans are familiar. Furthermore, we take from this that even for the generation of a subjective experience, which does not necessarily equate to self-awareness, an assumption is required.</p>
                <p className="paragraph">Another condition that needs to be met is for humans to have the required technological capabilities in a ​​posthuman era. And even if all conditions are met, according to this hypothesis, there are three consequent possibilities, only one of which leads to our simulation. The three possibilities are: (1) civilization will cease to exist before it gets advanced enough, (2) civilization does become capable but decides not to simulate any worlds, (3) the advanced human civilization decides to make simulations.</p>
                <p className="paragraph">Therefore, the validity of the small possibility of this hypothesis depends on another unestablished position, which does not meet the needs of the hypothesis, and the unknown. This makes it a hopeless endeavor and should not be taken seriously by the people of reason. However, because we live in the era of Elon and PlayStation, such theories do get an undeserving amount of attention.</p>
                <h4>The Conceptualization of Existence Is a Priori</h4>
                <p className="paragraph"><br/>Now that our existence has been established, it is important to discuss how it is conceptualized so that we may get a glimpse of the immense power of consciousness. One of the debates that took place between Muslim scholars was whether the conceptualization of existence requires reasoning or not. The position favored in most reputable books of Kalām (Islamic theology), and expressed by Imam al-Taftazani in <i>The Purgation of Kalam (Tahzib al-Kalam)</i> is that:</p>
                <p className="paragraph_arabic">تصور الوجود ضروري</p>
                <p className="paragraph"><i>“The conceptualization of existence is axiomatic.” [7]</i></p>
                <p className="paragraph">Similarly, after mentioning all other positions, in <i>The Commentary of Positions (Sharh al-Mawaqif)</i>, As-Sayid al-Sharif chooses the same opinion by stating: </p>
                <p className="paragraph_arabic">(فقيل إنه بديهي) تصوره فلا يجوز حينئذ أن يعرف إلا تعريفا لفظيا و قيل هو كسبي فلابد حينئذ من تعريفه و قيل لا يتصور أصلا لا بداهة و لا كسبا و المختار أنه بديهي</p>
                <p className="paragraph"><i>“(<b>And it is said it is a priori</b>), its [existince’s] conceptualization, then it is not permissible in that case for it to be defined except by synonyms. And it is said that it is deductive, in which case it must have a definition. And it is said it is not possible for it to be conceptualized not by intuition nor acquisition. And the chosen position is that it is axiomatic.” </i>[8]</p>
                <p className="paragraph">Imam al-Jorjani goes on to mention one of the most convincing arguments for the favored position and says:</p>
                <p className="paragraph_arabic">(فقيل إنه بديهي لوجوه الأول أنه جزء وجودي) لأن المطلق جزء من المقيد بالضرورة (و هو متصور بالبديهة) لأن من لا يقدر على الكسب حتى البله و الصبيان يتصور وجوده قطعا.</p>
                <p className="paragraph"><i>“(<b>And it is said it is axiomatic for a few reasons, the first being the fact that it is a part of an existent being</b>), because the unrestricted is necessarily a segment of the restricted [for example green in the “green pen” is a part of it] (<b>and it [the restricted] is conceptualized axiomatically</b>) since the one who is not capable of acquisition, even the mentally challenged and children, certainly conceptualizes his existence [which is an existence specific/restricted to him].” </i>[8]</p>
                <p className="paragraph">In summary, not only is our conceptualization of our own existence axiomatic, but also our conceptualization of existence external to us. What is of interest for this paper, however, is the argument which was used to prove the axiomatic nature of existence in general. That argument, in brief, was since even a child, who does not have the ability to deductively reason, believes that he or she exists, the conceptualization of existence must be axiomatic. In other words, if you ask a child “are you here or are you not?”, they will say I am here. But since this fact becomes clear to us only after we are able to communicate with the child, the question of when an infant becomes aware of their existence remains a mystery. If we are able to prove that a child is conscious from birth, we can then prove that consciousness is not developed, as one’s brain develops, but rather granted.</p>
                <h4>When Do Humans Become Aware of Their Existence?</h4>
                <p className="paragraph"><br/>Before mentioning what Islam states in this regard, let’s answer this question through the recent findings of a scientific study. In the paper, <i>The Emergence of Human Consciousness: From Fetal to Neonatal Life</i>, Hugo Lagercrantz and Jean-Pierre Changeux conclude that:</p>
                <p className="paragraph"><i>“Conversely, the newborn infant exhibits in addition to sensory awareness specially to painful stimuli, the ability to differentiate between self and nonself touch, sense that their bodies are separate from the world, to express emotions, and to show signs of shared feelings.” </i>[9]</p>
                <p className="paragraph">Perhaps an observation that contributed to their conclusion is the following:</p>
                <p className="paragraph"><i>“An almost unique feature of the human newborn is crying. It produces characteristic sounds and grimaces with vigorous body movements to the extent that crying may be viewed as a distinct state of consciousness interpreted as an ‘honest signaling of need or vigor’ to obtain vital care from his mother. Newborns distinguish their own cry from the cry of another newborn. They respond significantly more with crying when hearing another newborn crying than when hearing their own cry.” </i>[9]</p>
                <p className="paragraph">Thus, what Arabs call al-Istihlal (the infant’s first loud cry) may be the clearest indication that a baby is born with consciousness. That consciousness may require the development of the brain and neurons to completely manifest and expose itself, but nevertheless, it is there. And the fact that we do not remember infanthood does not necessarily mean we were not conscious, but rather it means our brains had not fully developed to retain memories of those moments. This is similar to what an adult experiences when having a concussion and not remembering what happened immediately after.</p>
                <h4>The Words Used for Consciousness in the Quran and Hadith</h4>
                <p className="paragraph"><br />To analyze what Islam states about consciousness, it is important to shed some light on the words used for consciousness in the Quran and Hadith. One of the words used for consciousness is <i>nafs</i>. For example, God states in the holy Quran:</p>
                <p className="paragraph_arabic">اللَّهُ يَتَوَفَّى الْأَنْفُسَ حِينَ مَوْتِهَا وَالَّتِي لَمْ تَمُتْ فِي مَنَامِهَا ۖ فَيُمْسِكُ الَّتِي قَضَىٰ عَلَيْهَا الْمَوْتَ وَيُرْسِلُ الْأُخْرَىٰ إِلَىٰ أَجَلٍ مُسَمًّى ۚ إِنَّ فِي ذَٰلِكَ لَآيَاتٍ لِقَوْمٍ يَتَفَكَّرُونَ</p>
                <p className="paragraph"><i>“Allah takes the anfos (plural of <i>nafs</i>) when they die and those who do not die, in their sleep. Then He keeps the ones for which death was decreed and sends the others back to the body until a predetermined time. Indeed in that, there are signs for those who ponder” </i>(Quran, Surat az-Zumar 42)</p>
                <p className="paragraph">Another word used for consciousness is <i>rooh</i>. The prophet (pbuh) says in a hadith narrated by Imam al-Bukhari that:</p>
                <p className="paragraph_arabic">إِنَّ اللَّهَ قَبَضَ أَرْوَاحَكُمْ حِينَ شَاءَ ، وَرَدَّهَا حِينَ شَاءَ</p>
                <p className="paragraph"><i>“Allah (swt) takes your arwah (plural of <i>rooh</i>) when He wishes and returns them when He wishes” </i>(Bukhari, Hadith 570)</p>
                <p className="paragraph">Thus, based on Islamic teachings, the <i>rooh</i> which can also be referred to as the <i>nafs</i> is either consciousness or the source of consciousness. But consciousness cannot be the source of the <i>nafs</i>/<i>rooh</i> since the negation of a child does not result in the negation of the parent, unlike the reverse.</p>
                <p className="paragraph">Some may argue that the <i>rooh</i>/<i>nafs</i> and consciousness are not related since it is possible that God takes both the <i>nafs</i> and consciousness when a person sleeps, but He only mentions the <i>nafs</i> in the Quran. This is a weak argument since it would indicate God has not told us about the most crucial and manifest aspect of the human being. In the chapter of "al-Sajda" verses 7 through 9, He tells us about the creation of the body and the granting of the <i>rooh</i>. If a Muslim, therefore, argues that the <i>rooh</i> has no relationship with consciousness, then they are questioning the completeness of the Quran. However, if for any given reason this argument is made by a non-Muslim or even a Muslim, then the burden of proof falls upon them, as all Quranic verses, statements of the Prophet (pbuh), and common sense indicate otherwise.</p>
                <p className="paragraph">It is important, here, to differentiate between the <i>rooh</i> spoken about in this verse, which is specific to humans, and what humans and animals share that keeps them alive. Imam al-Ghazali states in <i>The Alchemy of Happiness</i>:</p>
                <p className="paragraph_arabic">اما چیزی دیگر هست که آنرا روح گویند و قسمت پذیر است، ولیکن آن روح ستوران نیز باشد. اما روح که ما آن را دل گوییم، محل معرفت خدای تعالی است و بهایم را این نباشد و این نه جسم است و نه عرض، بلکه گوهریست از جنس گوهر فرشتگان</p>
                <p className="paragraph"><i>“And there is something else which is called <i>rooh</i> and it is divisible, but that <i>rooh</i> is possessed by animals as well. However, the <i>rooh</i>, which we also call the heart, is the locus of the knowledge of God (the most high) and animals do not possess it. It is not a body nor an accident, rather it is an essence with the same genus as the essence of angels.” </i>[10]</p>
                <p className="paragraph">Essentially there are two things that are referred to as <i>rooh</i>. The first is the <i>rooh</i> that is exclusive to humans, which is either the locus of consciousness or consciousness itself, and the second is the <i>rooh</i> that is shared between humans and other animals. The aforementioned verse and hadith are referring to the former. This distinction explains why the human body still functions while sleeping if the <i>rooh</i> is extracted from it. What keeps the body functioning is the <i>rooh</i> shared between animals and humans, and when dying not only is the shared <i>rooh</i> extracted but also the <i>rooh</i> that is exclusively human.</p>
                <p className="paragraph">Now that we know what words are used for consciousness in the Quran and Prophetic tradition, we can answer the question “when does an infant become conscious based on the teaching of Islam?”. From this point forward consciousness, <i>nafs</i>, and <i>rooh</i> will be used interchangeably.</p>
                <p className="paragraph">According to prophetic tradition, <i>rooh</i> is blown into the fetus four months after the pregnancy, meaning four months is the lower boundary. Prophet Muhammad (pbuh) says:</p>
                <p className="paragraph_arabic">إنَّ أحدَكُم يُجمَعُ خلقُهُ في بطنِ أمِّهِ أربعينَ يوماً ثمَّ يَكونُ في ذلك عَلقةً مثلَ ذلِكَ، ثمَّ يَكونُ مضغةً مثلَ ذلِكَ، ثمَّ يرسلُ الملَكُ فينفخُ فيهِ الرُّوحَ</p>
                <p className="paragraph"><i>“Verily the creation of each one of you is brought together in his mother’s womb for forty days in the form of a nutfah (a drop), then he becomes an alaqah (clinging substance) for the same duration, then a mudghah (a piece of flesh) for the same duration, then there is sent to him the angel who blows his soul into him” </i>(Bukhari, Hadith 3208)</p>
                <p className="paragraph">Thus, regarding consciousness in infants, it appears that empirical observations and Islam are not in disagreement.</p>
                <p className="paragraph">But does Islam explain the nature of consciousness, which continues to puzzle scientists to this day? When a group of Jews came and asked the Prophet (pbuh) about the <i>rooh</i>, the following verse was revealed in response:</p>
                <p className="paragraph_arabic">و يَسْأَلُونَكَ عَنِ الرُّوحِ ۖ قُلِ الرُّوحُ مِنْ أَمْرِ رَبِّي وَمَا أُوتِيتُمْ مِنَ الْعِلْمِ إِلَّا قَلِيلً</p>
                <p className="paragraph"><i>“And they will ask you about the soul, say the soul is from the commandments of my lord and you have not been given knowledge except little” </i>(Quran, Surat al-Israa 85)</p>
                <p className="paragraph">It appears science’s inability to explain consciousness, also, is in agreement with Islam’s teachings on whether consciousness can be explained. Allah (swt) constantly encourages human beings to use their intellect in the Quran, but when it comes to consciousness, He did not. Instead, He said the knowledge you have is limited, hinting at the fact that the <i>rooh</i> will never be completely understood. It exposed mankind's inability to understand consciousness 1400 years ago. One can argue that this is a clear indication that Prophet Muhammad (pbuh) was a messenger from God (the most high), since if he was anything but, he would have surely proposed his own theory.</p>
                <h4>What Does Consciousness Indicate?</h4>
                <p className="paragraph"><br/>As humans, it may be time to admit we will never fully understand consciousness and ask a different question, which is “what does consciousness indicate?”. Muslims believe that consciousness indicates the existence of a source eternal in the past and future. In fact, they believe it is a clear and unchallengeable sign of God. The Quran states regarding the different signs of God:</p>
                <p className="paragraph_arabic">سَنُرِيهِمْ آيَاتِنَا فِي الْآفَاقِ وَفِي أَنْفُسِهِمْ حَتَّىٰ يَتَبَيَّنَ لَهُمْ أَنَّهُ الْحَقُّ ۗ أَوَلَمْ يَكْفِ بِرَبِّكَ أَنَّهُ عَلَىٰ كُلِّ شَيْءٍ شَهِيد</p>
                <p className="paragraph"><i>“We will show them our signs in the horizon and in their anfos (plural of <i>nafs</i>) until it becomes clear to them that Allah (the Quran, the Prophet) is the truth, does it not suffice that your lord is a witness to all things.” </i>(Quran, Fussilat 53)</p>
                <p className="paragraph">This verse puts the signs indicating the truth of God into two categories. First, the external signs manifested in the material world, referred to as the signs on the horizon. And second, the signs that exist within the human, referred to as the signs in the <i>nafs</i>. The classic syllogisms that the Motakalimoon have used to prove the existence of God through the signs in the horizon are:</p>
                <p className="paragraph_arabic">
                    <ol>
                        <li>العالم متغير وكل متغير حادث فالعالم حادث</li>
                        <li>العالم حادث و لكل حادث محدث فللعالم محدث</li>
                    </ol>
                </p>
                <p className="paragraph">
                    <i>
                        <ol>
                            <li>The universe changes; everything that changes is an innovation; therefore, the universe is an innovation.</li>
                            <li>The universe is an innovation; everything that is an innovation has an innovator; therefore the universe has an innovator.</li>
                        </ol>
                    </i>
                </p>
                <p className="paragraph">To prove the existence of God through the signs in the <i>nafs</i>, however, Muslim scholars did not use Aristotelian logic. The <i>nafs</i> is not something that can be explained, therefore, in search of God's signs in it, Aristotelian logic will be of little benefit. The intellect must, however, allow us to establish its immaterial nature as an external detemriner, since establishing this purely with the nafs itself will put us in circular reasoning. One of the strongest proofs for the fact that the <i>nafs</i> is not physical is the following:</p>
                <p className="paragraph">
                    <ol>
                        <li>The existence of material things is observed exclusively through one of the five senses or the intellect (deductive reasoning).</li>
                        <li>Even those who lack all senses and do not have the ability of deductive reasoning observe their consciousness at every moment.</li>
                        <li>Therefore, consciousness is immaterial.</li>
                    </ol>
                </p>
                <p className="paragraph">Expressed differently:</p>
                <p className="paragraph">
                    <ol>
                        <li>Anything that can be observed without any of the five senses and the intellect is immaterial.</li>
                        <li>Consciousness can be observed without the five senses and the intellect.</li>
                        <li>Therefore, consciousness is immaterial.</li>
                    </ol>
                </p>
                <p className="paragraph">For this reason, to witness the signs of God that are manifest in the <i>nafs</i>, Muslims turned to a spiritual science deeply rooted in the religion of Islam known by many names such as Ihsan, Tazkya, and Tasawwuf. The origin of this science comes from the following statement of prophet Muhammad (pbuh) in response to a question asked by the angel Gabrial about Ihsan:</p>
                <p className="paragraph_arabic">قال مَا الإِحْسَانُ قَالَ أَنْ تَعْبُدَ اللَّهَ كَأَنَّكَ تَرَاهُ، فَإِنْ لَمْ تَكُنْ تَرَاهُ فَإِنَّهُ يَرَاكَ</p>
                <p className="paragraph"><i>“Said (Gabrial) what is Ihsan, he said (the prophet pbuh) that you worship God as if you see him, for if you do not see him, indeed he sees you” </i>(Bukhari, Hadith 50)</p>
                <p className="paragraph">The first part of this statement reveals the goal of Ihsan, which is reaching a state of shohood (witnessing God) or rather, being completely God conscious. And the second part of the statement expresses the way one can reach that goal, which is having the ultimate self-consciousness (being mindful of how God sees us). In other words, the prophet (pbuh) is telling us that to be conscious of God and witness his presence, one first needs to be conscious of his or her own self.</p>
                <p className="paragraph">It wouldn’t be far fetched if one argued that this sound Hadith is where the following saying of Sufis is derived:</p>
                <p className="paragraph_arabic">من عرف نفسه عرف ربه</p>
                <p className="paragraph"><i>“Whoever knows himself knows his lord” </i>[10]</p>
                <p className="paragraph">Imam al-Ghazali says, regarding this statement, in <i>The Alchemy of Happiness</i>:</p>
                <p className="paragraph_arabic">و این کلمه دلیل آنست که نفس آدمی چون آینه است که هر که در وی نگرد حق را میبیند</p>
                <p className="paragraph"><i>“And this statement is an indication of the fact that the <i>nafs</i> of a human is like a mirror, whoever looks into it will see Haq (God).” </i>[10]</p>
                <p className="paragraph">That is what al-Allama Mawlawi is referring to in <i>al-Fazeela</i> when he says:</p>
                <p className="paragraph_arabic">في بصر الظاهر باطن و في - بصيرة الباطن ظاهر وفي</p>
                <p className="paragraph"><i>In the manifest eye, He is hidden and in - The hidden eye he is clearly manifest </i>[11]</p>
                <p className="paragraph">If that is the case, however, why is it that the majority of people do not witness the existence of God? After all, every single human being does possess consciousness. The answer is that an unclean mirror will not display what is in front of it.</p>
                <p className="paragraph">Impurities such as the ego, lust, and the diseases of the heart are the veils between God and the human being as they contaminate consciousness. Once consciousness is refined and returns to its pure and original state, then the knowledge of God will flow within the human being. Additionally, if a person remains in this state long enough, they will reach a point where they will witness only God and nothing else. This is the state al-Shaykh Ahmad al-Ghazali, the brother of Imam al-Ghazali, is referring to in the book <i>Risaleye Sawanih</i> when he says:</p>
                <p className="paragraph_arabic">چون تو [عاشق] من [معشوق] گردي، در معشوق افزاید، همه معشوق بود عاشق نی، همه ناز بود نیاز نی، همه یافت بود درد بایست نی، همه توانگری بود و درویشی نی، همه چاره بود و بیچارگی نی</p>
                <p className="paragraph"><i>“Once you [the lover] become me [the beloved], all will become the beloved and the lover will cease, all will become needlessness and need will cease, all will become discovery and pain will cease, all will become riches and poverty will cease, all will become solutions and helplessness will cease.” </i>[12]</p>
                <p className="paragraph">The Shaykh is stating that once the “I” of the ego is erased and the <i>rooh</i> is refined, God, the beloved, will so clearly manifest Himself in the human being that the lover, the human, will forget about himself or herself and observe nothing but God. The same concept is conveyed in the following poem of Mawlana Rumi:</p>
                <p className="paragraph_arabic"> آن یکی آمد در یاری بزد	&emsp;گفت یارش کیستی ای معتمد</p>
                <p className="paragraph_arabic">گفت من گفتش برو هنگام نیست	&emsp;بر چنین خوانی مقام خام نیست</p>
                <p className="paragraph_arabic">خام را جز آتش هجر و فراق &emsp;	کی پزد کی وا رهاند از نفاق</p>
                <p className="paragraph_arabic">رفت آن مسکین و سالی در سفر&emsp;  	در فراق دوست سوزید از شرر</p>
                <p className="paragraph_arabic">پخته گشت آن سوخته پس بازگشت&emsp; 	باز گرد خانهٔ همباز گشت</p>
                <p className="paragraph_arabic">حلقه زد بر در بصد ترس و ادب&emsp;	    تا بنجهد بی‌ادب لفظی ز لب</p>
                <p className="paragraph_arabic">بانگ زد یارش که بر در کیست آن&emsp;    گفت بر در هم توی ای دلستان</p>
                <p className="paragraph_arabic">گفت اکنون چون منی ای من در آ&emsp;  	 نیست گنجایی دو من را در سرا</p>
                <p className="paragraph"><i>Someone knocked on the door of a beloved &nbsp;&nbsp;&nbsp;&nbsp; Said the beloved who art thou oh trustworthy</i></p>
                <p className="paragraph"><i>Said, I, Said begone it is not time for thee &nbsp;&nbsp;&nbsp;&nbsp; On such a table there is no place for the raw</i></p>
                <p className="paragraph"><i>Other than the fire of parting and separation &nbsp;&nbsp;&nbsp;&nbsp; who will cook the raw who will erase his hypocrisy</i></p>
                <p className="paragraph"><i>The wretched man left and for a year in travel &nbsp;&nbsp;&nbsp;&nbsp; in the separation of the beloved burnt by flames</i></p>
                <p className="paragraph"><i>Was cooked that burnt man and then returned &nbsp;&nbsp;&nbsp;&nbsp; Again approached the house of his beloved</i></p>
                <p className="paragraph"><i>Knocked with a hundred fears and etiquettes &nbsp;&nbsp;&nbsp;&nbsp; So disrespect does not come out of his mouth</i></p>
                <p className="paragraph"><i>The beloved called who is by the door &nbsp;&nbsp;&nbsp;&nbsp; He said in front of the door is also you oh taker of hearts</i></p>
                <p className="paragraph"><i>Said now that you are me, oh me, come in &nbsp;&nbsp;&nbsp;&nbsp; Two I’s cannot fit in the same location </i>[13]</p>
                <p className="paragraph">As hinted towards in this poem, it must be mentioned that according to the scholars of Tasawwuf and those who have gone through the path, this station is not reached very easily. One needs to struggle to cleanse his or her consciousness from the ego, lust, and the diseases of the heart. And after this process of purification, consciousness will manifest itself in its pure state. Similar to how a traveler through the desert will see a different version of himself in the mirror after taking a shower.</p>
                <p className="paragraph">However, prior to this struggle to refine one’s consciousness, a person will first need to be exposed to the impurities that have contaminated it, and this can be a very devastating experience. Most human beings believe they have no major shortcomings and when they realize that is not the case, they either go into a state of denial caused by the pain or step foot on the path of purification. The great Muslim poet Attare Naishapoori, who is considered to be the role model of Mawlana, writes in <i>Mantiq al-Tair</i>:</p>
                <p className="paragraph_arabic"> در درون هر کسی هست این خطر&emsp;	سر برون آرد چو آید در سفر</p>
                <p className="paragraph_arabic">تو ز خوک خویش اگر آگه نه‌ای&emsp;	سخت معذوری که مرد ره نه‌ای</p>
                <p className="paragraph_arabic">گر قدم در ره نهی چون مرد کار	&emsp;هم بت و هم خوک بینی صد هزار</p>
                <p className="paragraph_arabic">خوک کش، بت سوز، اندر راه عشق&emsp;	ورنه همچون شیخ شو رسوای عشق</p>
                <p className="paragraph"><i>This danger exists within everyone - It exposes itself once it is agitated in travel</i></p>
                <p className="paragraph"><i>If you are not aware of your inner pig	 - You are excused, for you are not the man of the path</i></p>
                <p className="paragraph"><i>If you step on the path like the man of action	 - You will see both pigs and idols in the thousands</i></p>
                <p className="paragraph"><i>Kill pigs and burn idols on the path of love - If not, like the Shaikh you will be ruined by love </i>[14]</p>
                <p className="paragraph">Even though this inner struggle is full of challenges, its reward is so great that it makes every step of the way worthwhile. That reward is reclaiming our connection to the source of consciousness. God says in the Quran:</p>
                <p className="paragraph_arabic">ثُمَّ سَوَّاهُ وَنَفَخَ فِيهِ مِنْ رُوحِهِ ۖ وَجَعَلَ لَكُمُ السَّمْعَ وَالْأَبْصَارَ وَالْأَفْئِدَةَ ۚ قَلِيلًا مَا تَشْكُرُونَ</p>
                <p className="paragraph"><i>“Then He gave him proportion and breathed into him of His <i>rooh</i> and made for you hearing and sight and intellects, it is very little the gratitude you show.” </i>(Quran, as-Sajda 9)</p>
                <p className="paragraph">With this verse, we reach the conclusion of this paper. Consciousness, being directly from God and from His commandments, offers the fastest path to Him. Unlike the body which is created from the material world, the <i>rooh</i> is an immaterial sample granted to every human being by God to serve as a direct channel to Him. Not being bound by the laws of the physical world, it has the ability to make a leap and get a glimpse of the unseen. But to be able to do so, Muslims believe it first needs to detach itself from the shackles of the material world, for those shackles prevent it from ascending to God. It is a journey full of challenges, but one’s company along the way and the destination make it full of meaning and purpose. Before we could make a case for the above argument, it was crucial that we first establish the concrete nature of our existence. Furthermore, we had to provide evidence for the fact that the conceptualization of our existence is a priori. This set the stage for proving the immaterial nature of consciousness by demonstrating that its observation does not require the senses nor the intellect. It was also crucial to analyze the beginning of consciousness in human beings from a scientific perspective. Only after laying this required foundation through reason and experience, we were able to introduce what God tells us about consciousness in the book Muslims believe is the last message from God. And what we learn from this seal of messages is that the channels through which it is decoded and God is perceived, the 7.674 billion <i>rooh</i>s on earth, are increasing and have not been sealed.</p>
                <h6 align="left"><br/><i>By Souhayl Maronesy</i></h6>
                <h6 align="left"><i>09/18/2021</i></h6>
                <h5 align="left"><br/>References:</h5>
                <p className="paragraph"><br/><i>[1]  An Essay Concerning Human Understanding, John Locke, Thomas Tegg and Son, 1836, p 59, 27th ed</i></p>
                <p className="paragraph"><i>[2]  A Commentary on the Creed of Islam, Sa'd al-din al-Taftazani, Translated by Earl Edgar Elder, Columbia University Press, 1950, p 48</i></p>
                <p className="paragraph"><i>[3] al-Nibras, Abdul-Aziz ibn Ahmad al-Miltani, Yasin 2012, 1st ed, p 62</i></p>
                <p className="paragraph"><i>[4] Discourse on Method, René Descartes, Global Grey (2018), p 23</i></p>
                <p className="paragraph"><i>[5] Are You Living in a Computer Simulation?, Nick Bostrom, Philosophical Quarterly 2003, Vol. 53, No. 211, pp. 243‐255.</i></p>
                <p className="paragraph"><i>[6] Energy Requirements Undermine Substrate Independence and Mind-Body Functionalism, Paul Thagard, Philosophy of Science 2021, p 1</i></p>
                <p className="paragraph"><i>[7] ​​Taghreeb al-Maraam (a commentary on Tahzib al-Kalaam of al-Taftazani), Abdul Qadir Takhti, Dar al-Kotob al-Elmiyah 2017, 1st ed, p 42</i></p>
                <p className="paragraph"><i>[8] The Commentary of Positions (Sharh al-Mawaqif), As-Sayid al-Sharif al-Jorjani, al-Quds 2018, Vol 1, 1st ed, pp 196-97</i></p>
                <p className="paragraph"><i>[9] Lagercrantz, H., Changeux, JP. The Emergence of Human Consciousness: From Fetal to Neonatal Life. Pediatr Res 65 (2009), pp 255–260</i></p>
                <p className="paragraph"><i>[10] ​​The Alchemy of Happiness, Abu Hamid al-Ghazali, Chaapkhaaneye Markazi (1944), 2nd ed, pp 9-13</i></p>
                <p className="paragraph"><i>[11] al-Wasila (the commentary on al-Fazila of Abdul Raheem al-Mawlawi), Abdul Kareem al-Mudarris, al-Irshad publishing (1972), 1st ed, p 21</i></p>
                <p className="paragraph"><i>[12] Risaleye Sawanih, Ahmad al-Ghazali, Yalda Qalam (2014), 1st ed, p 34</i></p>
                <p className="paragraph"><i>[13] Masnavi Ma’nawi, Mawlana Jalaludeen al-Rumi, Ganjoor, book 1, poem 144, verse 1-8</i></p>
                <p className="paragraph"><i>[14] Mantiq al-Tair, Faridodeen al-Nashaapori, Ganjoor, The answer of Hodhod, verses 240-243</i></p>
                <br/><br/><br/>
            </div>
        )
    }
}
