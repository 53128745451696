import React, { Component } from 'react'

export default class Contradiction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            like: 0,
            dislike: 0
        }
    }

    likeIncrement = () => {
        this.setState({ like: this.state.like + 1})
    }

    dislikeIncrement = () => {
        this.setState({ dislike: this.state.like + 1})
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Does Prayer Contradict the Decree of God?"
    }

    render() {
        return (
            <div className="webpage_content">
                <div className="like_dislike">
                    <div className='like'>
                        <input onClick={this.likeIncrement} type="image" src="../../../assets/article_dislike1.png" alt="dislike" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.like}</div> */}
                    <div className='dislike'>
                        <input onClick={this.dislikeIncrement} type="image" src="../../../assets/article_like1.png" alt="like" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.dislike}</div> */}
                </div>
                <img className="poster" src="../../assets/pray_decree_bw.png" alt="Alkalām Research Institute (ARI)"/> {' '}
                
                <h3><br/>Does Prayer Contradict the Decree of God?</h3>
                <p className="paragraph"><br />“There are major contradictions in religion”, the driver said, very respectfully, while taking his passenger to give the Friday sermon. He continued, “For example, how can you say ‘everything is God’s plan’ and then pray for things to happen or not happen? If it is God’s plan, it will happen regardless of whether you pray or not.” According to the driver, there is a contradiction between prayer and God’s plan, because prayer assumes the ability to influence the future and God’s plan assumes a predetermined future. It is very easy to submit to the driver's argument, and the main reason behind this quick submission is the lack of conviction. It is conviction that prevents one from mistaking doubt for contradiction. Thus, if we take conviction away, in the eyes of the unconvinced, mere doubts have the potential of turning into devastating contradictions. Conviction allows one to find answers which otherwise would remain undiscovered and reconcile things that would otherwise remain at odds. That is why Allah (swt) says:</ p>
                <p className="paragraph_arabic">قَدْ بَيَّنَّا الْآيَاتِ لِقَوْمٍ يُوقِنُونَ</p>
                <p className="paragraph"><i>“Verily we have clarified the signs for a people who have certainty”</i>(Quran, al-Baqarah, verse 118)</p>
                <p className="paragraph">Rational reconciliation through the power of conviction is perhaps nowhere more evident than in the great work of Imam al-Shafei, in the science of Usul, al-Risalah. From al-Risalah, we learn that when challenged with two religious texts that appear to be at odds, it is impermissible to see a contradiction. To the contrary, we do not stop until we reconcile them, as it is impossible for the prophet (saw) to contradict himself or the Quran and vice versa. If this approach is not taken, the slightest ambiguity can be seen as a contradiction, forcing one to associate the concomitant of contradiction (the dismissal of that which is in contradiction) with a mere doubt. In the case of the driver, what necessarily follows the contradiction between prayer and God’s decree is the dismissal of religion.</ p>
                <p className="paragraph">As you may have guessed already, the word under scrutiny, in this paper, is ‘contradiction’. Contradiction is often incorrectly used to describe concepts that are incomprehensible and cause doubt. Since we cannot expect everyone to rely on the power of conviction to be protected from wrongly attributing a contradiction to things that are not at odds, in this paper, a purely rational system, which can be applied to both theological and non-theological dilemmas, will be presented. To accomplish this task, we will first explain the concept of contradiction and list its conditions. We will then apply our enhanced understanding of contradiction to the driver’s argument and demonstrate how he saw a contradiction where there was none. Once we have successfully done this, we will analyze some religious texts that, in the eyes of some, are at odds. To conclude, we will invite the reader to apply what they have learned in this paper to the ideas, concepts, ahadith, and verses in which they see a contradiction.</ p>
                <h4>What is a Contradiction?</h4>
                <p className="paragraph"><br/>What does it really mean for two prepositions to contradict each other? As Imam al-Akhdhari states in al-Sollam:</ p>
                <p className="paragraph_arabic">تناقض خلف القضيتين في &emsp; كيف وصدق واحد أمر قفي <br/>  فإن تكن شخصية أو مهملة &emsp;	فنقضها بالكيف أن تبدله <br/> و إن تكن محصورة بالسور &emsp; فانقض بضد سورها المذكور </p>
                <p className="paragraph"><i>Contradiction is the difference of two premises &nbsp;&nbsp;&nbsp;&nbsp; In quality (assertion/negation) and only one is true <br/>If it is a personal or neglected statement &nbsp;&nbsp;&nbsp;&nbsp; Finding its contradiction is by changing the quality <br/>And if it is a confined statement by boundaries &nbsp;&nbsp;&nbsp;&nbsp; Contradict it with the opposite of its boundary</i> [1]</p>
                <p className="paragraph">Having written al-Sollam al-Monawraq for beginners, Imam al-Akhdhari does not provide all of the requirements for the contradiction of two statements. In Tahzeeb al-Mantiq, Imam al-Taftazani states regarding contradiction (tanaaquz) and its criteria:</ p>
                <p className="paragraph_arabic">التناقض اختلاف القضيتين بحيث يلزم لذاته من صدق كل منهما كذب الآخر و بالعكس و لابد من الاختلاف في الكم و الكيف و الجهة و الاتحاد في ما عداها</p>
                <p className="paragraph"><i>“The difference between two statements in a manner which necessitates, due to the difference itself, the falsehood of one of them because of the truth of the other and vice versa. And the difference in quantity, affirmation or negation, and direction, and agreement in other than them is a must”</i> [2]</p>
                <p className="paragraph">The parts of the prepositions, which Imam al-Taftazani states need to be in agreement, are gathered in the following verses of poetry:</ p>
                <p className="paragraph_arabic">در تناقض هشت وحدت شرط دان&emsp;وحدت موضوع و محمول و مكان <br/> وحدت شرط اضافه جزء و كل&emsp;&emsp;&nbsp;قوه و فعل است در آخر زمان</p>
                <p className="paragraph"><i>In contradiction consider the agreement of 8 things a condition <br/>The agreement of the subject, the predicate, and location <br/>The agreement of the condition, association, the part, and the whole <br/>Potentiality, actuality, and time</i> [3]</p>
                <p className="paragraph">From the explanation above, we learn that for two statements to contradict each other, the following conditions need to be met:</ p>
                <table>
                    <tr>
                        <th>#</th>
                        <th>Condition</th>
                        <th>Preposition</th>
                        <th>Contradictory preposition</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>In the case of personal prepositions, the affirmation and negation of the two prepositions need to be different</td>
                        <td>Ahmed <b>is</b> standing</td>
                        <td>Ahmed <b>is not</b> standing</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>The bounds in confined prepositions need to be different along with the affirmation and negation</td>
                        <td><b>Some animals</b> are human</td>
                        <td><b>No animal</b> is human</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>If the orientation is mentioned, the difference in the orientation of the prepositions along with the difference in the bounds and the affirmation and negation</td>
                        <td>All humans can <b>necessarily</b> read</td>
                        <td>Some humans <b>possibly</b> cannot read</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>The subjects of both prepositions need to be the same</td>
                        <td><b>Ahmed </b>is standing</td>
                        <td><b>Ahmed </b>is not standing</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>The predicates of both prepositions need to be the same</td>
                        <td>Ahmed is <b>standing</b></td>
                        <td>Ahmed is not <b>standing</b></td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>The location of both prepositions needs to be the same</td>
                        <td>Ahmed is standing on the <b>roof</b></td>
                        <td>Ahmed is not standing on the <b>roof</b></td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>The condition of both prepositions needs to be the same</td>
                        <td><b>If healthy</b>, Ahmed is at work</td>
                        <td><b>If healthy</b>, Ahmed is not at work</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>The association existing in both prepositions needs to be the same</td>
                        <td>Zaid is <b>Bakr’s son</b></td>
                        <td>Zaid is not <b>Bakr’s son</b></td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>The specific or encompassing nature of the subjects of both prepositions needs to be the same</td>
                        <td>Zaid is white, <b>meaning his hair</b></td>
                        <td>Zaid is not white, <b>meaning his hair</b></td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>The potentiality and actuality of both prepositions need to be the same</td>
                        <td>Zaid has the <b>potential</b> to write</td>
                        <td>Zaid does not have the <b>potential</b> to write</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>The time of both prepositions needs to be the same</td>
                        <td>Zaid is standing at <b>this moment</b></td>
                        <td>Zaid is not standing at <b>this moment</b></td>
                    </tr>
                </table>
                <p className="paragraph"><br/>It is only after the above conditions are met in two prepositions that one can claim they contradict each other. If any of the conditions are not met, contradiction cannot be claimed between them. Morever, if any of the conditions are unknown, the contradiction between the two prepositions is also unknown.</ p>
                <p className="paragraph">It must also be noted that the existence of the above conditions, hence a contradiction, can be properly assessed only if the true reality of the subject and the predicate is understood. This comprehensive knowledge, relative to the nature of the assertion, is necessary since one cannot claim anything, let alone a contradiction, about a subject one does not understand. As the famous maxim states:</ p>
                <p className="paragraph_arabic">الحكم على الشيء فرع من تصوره</p>
                <p className="paragraph"><i>“Passing a judgment on something is a branch of the conceptualization of its reality.”</i></p>
                <p className="paragraph">To highlight the importance of these requirments for the propper assessment of the existence of contradiction, we will introduce the following two, pre-assesment, condition:</ p>
                <table>
                    <tr>
                        <th>#</th>
                        <th>Pre-condition</th>
                        <th>Assessible Preposition</th>
                        <th>Contradictory preposition</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Understanding the reality of the subject</td>
                        <td><b>I</b> can talk</td>
                        <td><b>I</b> cannot talk</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Understanding the reality of the predicate</td>
                        <td>The shape is a <b>triangle</b></td>
                        <td>The shape is not a <b>triangle</b></td>
                    </tr>
                </table>
                <h4><br/>Applying the conditions of contradiction to the statement of the driver</h4>
                <p className="paragraph"><br/>Now let’s demonstrate this with the driver's criticism of religion. In short, the driver's argument was that there is a contradiction between praying and believing in God’s pre-eternal plans. To be able to determine whether there is truly a contradiction between praying and God’s decree, we need to derive the two implicit and contradictory prepositions in the claim of the driver. The two prepositions need to have, for example, the same subject and predicate and be different in their negation or affirmation. When we do, we come up with the following two prepositions:</ p>
                <p className="paragraph">Derived from decree: God’s plan cannot change<br/>Derived from prayer: God’s plan can change</ p>
                <p className="paragraph">To give credit to the driver, this argument is very appealing at first glance. However, there is a baseless assumption being made about God’s plan, which in turn forces the driver to miss some of the conditions of contradiction. This is a great example of how not understanding the true reality of the subject can lead one to incorrectly claim contradiction between two proepostions in which the conditions of contradiction are not met. The false assumption being made by the driver is that God’s plan is linear when, in fact, there are no references in religion to the nature of God’s decree. Conversely, trying to understand the true reality of God’s decree is perhaps one of the most unwise things a believer can do. That is why Shaikh Hafiz says:</ p> 
                <p className="paragraph_arabic">حدیث از مطرب و می گو و راز دهر کمتر جو <br/> که کس نگشود و نگشاید به حکمت این معما را</p>
                <p className="paragraph"><i>“Tales of singers and wine relay, and seek less the secret of time <br/> For no one has ever nor ever will, with wisdom, solve this riddle” [4]</i></p>
                <p className="paragraph">To summarize, not having conceptualized the reality of God’s decree (the subject), we must not make definitive assertions about it, for doing so is an irrational undertaking. Moreover, the lack of having an encompassing understanding of God’s decree indicates that one of the two fundamental pre-conditions of contradiction is not met. And this should prevent us from making God's decree the subject of two opposing statements. With this in mind, this paper will not try to explain the reality of the decree of God, but it will rather present a possible alternative to the assumed linear nature of God’s decree in which there are absolutely no contradictions. And this should be enough to show that praying to influence what will take place does not necessitate the violation of God’s plans. </ p>
                <h4>Alternative assumptions in which there are no contradictions</h4>
                <p className="paragraph"><br/>Let us make a different assumption. Let us assume that God’s plan is tree-like with many branches and not a straight line. This type of planning is not foreign to humans as we take this approach in many areas of science. For example, in the field of computer science, programs are written with condition-based “if” and “else” statements, and each branch often leads to different branches and results. What needs to be highlighted here is the fact that the plan designed by a programmer includes all of the branches, not just one. Meaning that the plan is a tree and not linear. To make our example a bit more concrete, here is a simple high level (pseudo) code for determining the best path to a particular destination:</ p>
                <p className="paragraph"><i><b>If walking: <br/> &emsp; Take alley number one, as it is the shortest distance <br/> Else if riding a bike: <br/> &emsp; Take alley number two, as it does not have stairs <br/> Else:<br/> &emsp;If morning: <br/> &emsp; &emsp; Take city streets, as it has less traffic <br/> &emsp; If noon: <br/> &emsp; &emsp; Take Highway 8, as it has no traffic</b></i></ p>
                <p className="paragraph">We can consider the above code to be the plan for how a person decides what path to take. If we were to draw the above branches, it would look like a tree. Now, applying the same concept to the statement of the driver, we get the following plan:</ p>
                <p className="paragraph"><i><b>If X prays not to get in a car crash:<br/> &emsp;If he follows traffic laws: <br/> &emsp; &emsp; X will not get in a car crash <br/> Else if X does not pray not to get in a car crash: <br/> &emsp; X will get in a car crash</b></i></ p>
                <p className="paragraph">The car crash was the plan of God and the lack thereof, due to X’s prayer, was also the plan of God. Again, we must emphasize that this example is simply to show that the contradiction stems from our wrong assumption about God’s plan, not necessarily the plan itself. As we saw in the example above, if we make a different assumption about His plans, there are absolutely no contradictions. Whether the second assumption is correct or not is irrelevant to this argument. </ p>
                <p className="paragraph">Furthermore, after this new assumption, we realize that the following condition of contradiction is not met:</ p>
                <table>
                    <tr>
                        <th>#</th>
                        <th>Condition</th>
                        <th>Preposition</th>
                        <th>Contradictory preposition</th>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>The specific or encompassing nature of the subjects of both prepositions needs to be the same</td>
                        <td>Zaid is white, <b>meaning his hair</b></td>
                        <td>Zaid is not white, <b>meaning his hair</b></td>
                    </tr>
                </table>
                <p className="paragraph"><br/>Here are the two modified prepositions:</ p>
                <p className="paragraph">God’s plan is not changeable, meaning the tree-like plan itself <br/> God’s plan is changeable, meaning the route which is taken in the plan</ p>
                <p className="paragraph">With our new assumption, it can be argued that some of the other conditions of contradciton are also not met in the prepositions. However, I will leave it up to the reader to identify those conditions.</ p>
                <h4>The assumption applied to the questions of Muslims</h4>
                <p className="paragraph"><br/>One of the sayings of the prophet (pbuh) that often becomes the subject of questions for Muslims is the following hadith, which is narrated by Ibn Masood (ra):</ p>
                <p className="paragraph_arabic">ثُمَّ يُرْسَلُ إلَيْهِ الْمَلَكُ فَيَنْفُخُ فِيهِ الرُّوحَ، وَيُؤْمَرُ بِأَرْبَعِ كَلِمَاتٍ: بِكَتْبِ رِزْقِهِ، وَأَجَلِهِ، وَعَمَلِهِ، وَشَقِيٍّ أَمْ سَعِيدٍ؛ فَوَاَللَّهِ الَّذِي لَا إلَهَ غَيْرُهُ إنَّ أَحَدَكُمْ لَيَعْمَلُ بِعَمَلِ أَهْلِ الْجَنَّةِ حَتَّى مَا يَكُونُ بَيْنَهُ وَبَيْنَهَا إلَّا ذِرَاعٌ فَيَسْبِقُ عَلَيْهِ الْكِتَابُ فَيَعْمَلُ بِعَمَلِ أَهْلِ النَّارِ فَيَدْخُلُهَا. وَإِنَّ أَحَدَكُمْ لَيَعْمَلُ بِعَمَلِ أَهْلِ النَّارِ حَتَّى مَا يَكُونُ بَيْنَهُ وَبَيْنَهَا إلَّا ذِرَاعٌ فَيَسْبِقُ عَلَيْهِ الْكِتَابُ فَيَعْمَلُ بِعَمَلِ أَهْلِ الْجَنَّةِ فَيَدْخُلُهَا</p>
                <p className="paragraph"><i>“Then is sent to him the angel who blows into him the soul and who is commanded with four matters: to write down his rizq (sustenance), his life span, his actions, and whether he will be happy or unhappy (i.e., whether or not he will enter Paradise). By the One, other than Whom there is no God, verily one of you performs the actions of the people of Paradise until there is but an arms-length between him and it, and that which has been written overtakes him, and so he acts with the actions of the people of the Hellfire and thus enters it; and verily one of you performs the actions of the people of the Hellfire until there is but an arms-length between him and it, and that which has been written overtakes him and so he acts with the actions of the people of Paradise and thus he enters it.”</i> (Bukhari, Hadith 3208)</p>
                <p className="paragraph">In contrast to the below hadith, which states that a person’s rizq and age will increase if they uphold kinship ties, some see a contradiction similar to the one the driver claimed. Anas the son of Malik reports:</ p>
                <p className="paragraph_arabic">أَنَّ رَسُولَ اللَّهِ صلى الله عليه وسلم قَالَ ‏مَنْ أَحَبَّ أَنْ يُبْسَطَ لَهُ فِي رِزْقِهِ وَيُنْسَأَ لَهُ فِي أَثَرِهِ فَلْيَصِلْ رَحِمَهُ</p>
                <p className="paragraph"><i>“Allah's Messenger (may peace be upon him) said: He who likes that his sustenance should be expanded and his age may be lengthened should uphold kinship ties.”</i> (Muslim, Hadith 6688)</p>
                <p className="paragraph">Before going any further, we must shed some light on the two different meanings of <i>kitab</i> (transcription). One meaning of <i>kitab</i> is the transcription of the knowledge of God, which is something reality cannot contradict since God is omniscient. And that is what is meant, in the first hadith, by <i>kitab</i> catching up to the people of hell and paradise and causing them to go to heaven or hell. Meaning, since they cannot escape what is in the knowledge of God, what is in his knowledge will always come to pass. That is why Imam al-Bukhari implies in his collection that <i>kitab</i> means the knowledge of God. He includes a chapter in his Sahih called:</ p>
                <p className="paragraph_arabic">باب جف القلم على علم الله</p>
                <p className="paragraph"><i>“The pen has dried upon the knowledge of God”</i> [5]</p>
                <p className="paragraph">This also explains the numerous ahadith about the fact that our destination on the day of judgment is already written. It must be reiterated that the knowledge of God and its transcription do not force any decisions upon a person, since knowledge is not an attribute that impacts.</ p>
                <p className="paragraph">The second meaning of <i>kitab</i> (transcription) is the transcription of the actual plan of God. And that is what could take the form of a tree, as explained previously and as explained by Imam Ibn Hajar (ra). Imam Ibn Hajar further explains that both meanings of <i>kitab</i> can be referred to as the ghadhar (decree) of God (swt). He says, in Fath al-Bari, regarding the hadith of upholding kinship ties to increase one’s wealth and age:</ p>
                <p className="paragraph_arabic">ثانيهما أن الزيادة على حقيقتها وذلك بالنسبة إلى علم الملك الموكل بالعمر وأما الأول الذي دلت عليه الآية فبالنسبة إلى علم الله تعالى كأن يقال للملك مثلا إن عمر فلان مائة مثلا إن وصل رحمه وستون إن قطعها. وقد سبق في علم الله أنه يصل أو يقطع فالذي في علم الله لا يتقدم ولا يتأخر والذي في علم الملك هو الذي يمكن فيه الزيادة والنقص وإليه الإشارة بقوله تعالى  [يمحو الله ما يشاء ويثبت وعنده أم الكتاب] فالمحو والإثبات بالنسبة لما في علم الملك وما في أم الكتاب هو الذي في علم الله تعالى فلا محو فيه ألبتة  ويقال له القضاء المبرم ويقال للأول القضاء المعلق.</p>
                <p className="paragraph"><i>“The second possibility is that the increase is real and this increase is relative to the knowledge of the Angle responsible for age. As far as the first meaning of decree which is mentioned in the verse, it is relative to the knowledge of God (swt). It is as if it was said to the Angel for example: ‘The age of so and so is hundred years if he upholds kinship ties, and 60 if he does not’ when it has already passed in the knowledge of God whether he will uphold kinship ties or not. Thus, that which is in the knowledge of God is not advanced nor postponed. And that which is with the angels, that is what accepts increase and decrease (depending on what action the servant chooses). And Allah (swt) is referring to this when he says ‘Allah (swt) erases what he wishes and upholds what he wishes and with him is the mother of the book’. Being erased and upheld are relative to the knowledge of the angel and that which is in the mother of the book is relative to the knowledge of God (swt), thus there is no erasing in it. The latter is called the firm decree (since God already knows what action the servant will choose) and the former the conditional decree (meaning dependent on the action of the servant).”</i> [6]</p>
                <p className="paragraph">In short, the first part of the hadith of Ibn Masood (ra) is referring to the decree that angels know, the conditional decree, the tree-like decree. And the second reference to <i>kitab</i>, which some have said is not part of the statement of the prophet (pbuh), but rather a statement made by ibn Masood, is referring to the knowledge of God. And since reality cannot oppose the knowledge of God, <i>kitab</i> (God’s knowledge) will always come to pass. Regarding the last part of the hadith, It is mentioned in Fath al-Barai that:</ p>
                <p className="paragraph_arabic"> وقد قيل إن قوله في آخر الحديث فوالله الذي لا إله غيره إن أحدكم ليعمل بعمل أهل الجنة إلى آخر الحديث مدرج من كلام ابن مسعود</p>
                <p className="paragraph"><i>“Verily it has been said that the statement at the end of the hadith, ‘And by the one who there is no God except him, indeed one of you will verily perform the actions of the people of paradise’ till the end, is added from the words of ibn Masood”</i> [6]</p>
                <p className="paragraph">It is very likely for this to be the case, as the first part of the Hadith is talking about what angels have been commanded to write and know and the second part is about that which is in the knowledge of God and cannot be escaped.</ p>
                <p className="paragraph">This possible explanation, given by Ibn Hajar and other scholars about the plan of God, validates our assumption of the tree-like nature of His plan. And our assumption demonstrates how the plan of God can be in such a way that is not in contradiction with prayer and more generally free will. One of the statements of the prophet (pbuh) that re-enforces the conditional nature of God’s plan is the following:</ p>
                <p className="paragraph_arabic">ما منكم من أحد إلا له منزلان منزل في الجنة ومنزل في النار فإذا مات فدخل النار ورث أهل الجنة منزله</p>
                <p className="paragraph"><i>“Every single one of you has two residences, a residence in heaven and a residence in fire and when someone dies and enters hell, the people of heaven inherit his place in heaven.”</i> (Ibn Majah, hadith 4341)</p>
                <p className="paragraph">This is a very clear indication that an individual has been granted the two general paths of good and evil. Furthermore, he or she also has a place reserved for them at the destinations of those two paths, heaven and hell. That is precisely why Allah (swt) says:</ p>
                <p className="paragraph_arabic">وَهَدَيْنَاهُ النَّجْدَيْنِ</p>
                <p className="paragraph"><i>“And we showed the human the two paths”</i> (Quran, al-Balad, verse 10)</p>
                <p className="paragraph">Returning to our tree-like assumption about God’s plan, we find that it is so effective in reconciliation that it allows us to reconcile between all explanations of the main verse pertaining to free will. Allah (swt) says in the Quran:</ p>
                <p className="paragraph_arabic">وَمَا تَشَاءُونَ إِلَّا أَنْ يَشَاءَ اللَّهُ رَبُّ الْعَالَمِينَ</p>
                <p className="paragraph"><i>“You do not want anything except that Allah, the lord of the world, wants it”</i> (Quran, al-Takweer, verse 29)</p>
                <p className="paragraph">The three explanations are:</ p>
                <p className="paragraph">
                    <i>
                        <ol>
                            <li>You do not want, except that Allah wants as well</li>
                            <li>You do not want, except after Allah wants</li>
                            <li>You do not have the power to will, except by the will and permission of Allah</li>
                        </ol>
                    </i>
                </p>
                <p className="paragraph">The first meaning indicates that the will of the human and the will of God happen at the same time, which does not necessitate God forcing his will upon humans. This is very clear if we take our tree-like assumption of God’s plan into consideration. We are not choosing anything except that it is a branch willed by God. </ p>
                <p className="paragraph">The second meaning, which indicates we cannot will anything except after God wills, also holds with our assumption in mind, since all of the branches in the decree of God are willed by Him before we get to choose one.</ p>
                <p className="paragraph">The third meaning indicates that we have been granted free will because Allah (swt) has willed for us to have free will. This also is in total harmony with our assumption, since God is the one who has willed for us to be able to choose from the branches/paths in front of us. </ p>
                <p className="paragraph">It should be clear by now that claiming there is a contradiction between the plan of God and our ability to influence what happens in our lives is inaccurate. Simply by changing our assumption about the plan of God, the “contradiction” claimed by the driver, the“contradictions” some see in the sayings of the prophet (pbuh), and the contradiction between the decree of God and free will cease to exist. Our assumption also gives a new meaning to the fact that no matter what we do we are confined by the will of God. Now, imagine how coherent everything would be if we were able to comprehend the true reality of God’s decree. </ p>
                <p className="paragraph">Yes, this does indicate that the options from which we can choose are limited to what is put on our path by Allah (swt), but remember, even if one does not believe in the plan of God, his or her options are still limited. At any given moment a person is presented with a set of options. No one is given the opportunity to choose from every single possibility that exists. The Ash’aris would call our choice al-kasb (acquisition) and our options and their consequences, al-khalq (creation). It is this creation that puts in front of us all of the branches in God’s plan for us, and it is our acquisition that allows us to choose one. Allah (swt) says:</ p>
                <p className="paragraph_arabic">كُلُّ امْرِئٍ بِمَا كَسَبَ رَهِينٌ</p>
                <p className="paragraph"><i>“Everyone is pledged for what he has acquired”</i> (Quran, al-Toor, verse 21)</p>
                <p className="paragraph">In conclusion, before one claims contradiction between two prepositions, he or she should ask themselves whether they understand the reality of the subject and the predicate of the prepositions that they believe are in contradiction. Moreover, they should ask whether all of the other conditions of contradiction mentioned in this paper are met. Only then, the word contradiction may be used. This is crucial, especially when we talk about God, as the true reality of God and his attributes are unknown. As demonstrated, we were able to prove that there are no contradictions between the concepts many people find to be at odds simply by rectifying our understanding of contradiction. The comprehensive explination of the concept of contradiction presented in this paper can be applied to any two prepositions between which there seems to be a conflict. Readers are encouraged, especially believers, to apply the system laid out in this paper to the things they consider contradictory and not retreat at the first sign of doubt.</ p>
                <p className="paragraph">If you are wondering about the driver and the Muslim man, please read on. After the driver made his claim, the Muslim man said to him, “As a beleiver, I should be able to provide a rational response to your argument, but that response will require much longer than a few minutes. If you would like to exchange contact information, we can continue this discussion”. There was a moment of silence and contemplation, but no answers were given. That is when they reached the masjid. The Muslim man thanked the driver and existed the car. This article is the response the Muslim man was hoping to share with the driver.</p>


                <h6 align="left"><br/><i>By Souhayl Maronesy</i></h6>
                <h6 align="left"><i>11/17/2021</i></h6>
                <h5 align="left"><br/>References:</h5>
                <p className="paragraph"><br/><i>[1] al-Sollam al-Monawraq, Abdul Raman ibn Muhammad al-Saqeer al-Akhdhari, Hashemi 2014, 3rd ed, p 9</i></p>
                <p className="paragraph"><i>[2] Tahzib al-Mantiq, Sa'd al-din al-Taftazani, Wafaa 2011, 3rd ed, p 126</i></p>
                <p className="paragraph"><i>[3] The Commentary of Mulla Abdullah on Tahzib al-Mantiq of al-Taftazani, Mulla Abdulla Hussain ibno Yazdi, Wafaa 2011, 3rd ed, pp 126-128</i></p>
                <p className="paragraph"><i>[4] Divan of Hafiz, Haifz, Ganjor, ghazal 3, verse 8</i></p>
                <p className="paragraph"><i>[5] Sahih al-Bukhari, Imam al-Bukhari, The chapter of decree, vol 4, p 210</i></p>
                <p className="paragraph"><i>[6] Fath al-Bari, al-Hafiz Ibn Hajar, The book of etiquettes, The chapter of “The one whose wealth increases through kinship ties” </i></p>
                <br/><br/><br/>
            </div>
        )
    }
}
