import React, { Component } from 'react'
import {
    Link
  } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet-async";

export default class CoherenceOfCreedOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            like: 0,
            dislike: 0
        }
    }

    likeIncrement = () => {
        this.setState({ like: this.state.like + 1})
    }

    dislikeIncrement = () => {
        this.setState({ dislike: this.state.like + 1})
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "The Coherence of Creed - Part One - Foundations"
    }

    render() {
        return (
            <div className="webpage_content">
                {/* <MetaTags>
                    <title>The Coherence of Creed - Part One - Foundations</title>
                    <meta property="og:title" content="The Coherence of Creed - Part One - Foundations" />
                    <meta property="og:image" content="../../assets/foundations.png" />
                </MetaTags> */}
                <Helmet>
                    <title>content="The Coherence of Creed - Part One - Foundations"</title> 
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content="https://alkalam.org/articles/coherence-of-creed-one" />
                    <meta property="og:image" content="../../assets/foundations.png" />
                </Helmet>
                <div className="like_dislike">
                    <div className='like'>
                        <input onClick={this.likeIncrement} type="image" src="../../../assets/article_dislike1.png" alt="dislike" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.like}</div> */}
                    <div className='dislike'>
                        <input onClick={this.dislikeIncrement} type="image" src="../../../assets/article_like1.png" alt="like" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.dislike}</div> */}
                </div>
                <img className="poster" src="../../assets/foundations.png" alt="Alkalām Research Institute (ARI)"/> {' '}
                <br/><br/><br/>
                <h2>The Coherence of Creed - Part One - Foundations</h2>
                <br/>
                <h5>In the name of Allāh, the one and only God</h5>
                <br/>
                <p className="paragraph">To know God is the main objective of our existence. That is why it becomes crucial to have the correct understanding of God and His attributes. Attaining this correct understanding is accomplished by making the Qur&apos;ān and the Prophetic tradition the criteria by which we develop our perception of God. However, these two sources of divine knowledge have been revealed and preserved in language; thus, our objective requires us to venture one level deeper and master the language of revelation. Furthermore, since the rules of language are rational, our conclusions about God (swt) must also be grounded in reason. If any of these layers are neglected, our understanding of God will become flawed. This paper attempts to demonstrate the coherence of the Islamic creed when analyzed using this layered approach by applying it to the topic of divine attributes studied in Islamic theology. The results of the application of this methodology are then presented to the readers.&nbsp;</p>
                <p className="paragraph">One of the topics Muslim theologians debated extensively, throughout history, is the topic of the attributes of God (swt). This topic has been a point of contention between Muslims adhering to different schools. Thus, this paper began as a researched response to the recent wave of attacks on the creed of the majority of Muslim scholars, the Ashʽarī creed. However, the details of these attacks and the initial motivation behind writing this paper have now become irrelevant. As the paper was being written and refutations were starting to be formulated and articulated, a profound reality was becoming more and more evident. As passages were read, one after another, and the reasonings of scholars were analyzed, from premise to conclusion, a mystery was starting to reveal itself, bit by bit. By the time most of the paper was written, an intriguing discovery was made, the revealing of which in the introduction would be premature. It is that discovery that became the new motivation behind the completion and publishing of this paper. In order for the reader to experience the same thought process and reach the same conclusion, the findings of this paper will not be presented until the end. Thus, it is requested from the reader to read this paper with a sincere heart that seeks nothing but the truth and with an intellect freed from the shackles of ego and imitation.</p>
                <br/>
                <h3>Establishing Foundations</h3>
                <br/>
                <p className="paragraph">As stated, this paper is about&nbsp;ʽaqīda&nbsp;(creed), and more specifically, the topic of the attributes of God (swt). To be able to understand the conclusions of this paper pertaining to this topic, the reader must first be introduced to foundations that have often been neglected in the discourse of divine attributes. Through these foundations, the conclusions of the paper become self-evident.</p>
                <br/><h4>Foundation One: The same word can have multiple literal meanings</h4><br/>
                <p className="paragraph">It is possible for the same word to have multiple literal meanings. In the science of al-wadʽ (lexical semantics and word coinage), we call these words mushtarak lafẓī (homonyms). Imam al-&rsquo;ījī, the pioneer of this science, defines al-mushtarak al-lafẓī &nbsp;in his commentary on the Mukhtasar al-Muntaha of Ibn Hājib al-Mālikī (ra) as follows:</p>
                <p className="paragraph_arabic">لفظ واحد لمعنى متعدد فان كان للمتعدد حقيقة فهو المشترك</p>
                <p className="paragraph_indented">A single word designated for multiple meanings, and if every meaning is literal for the word, then that is the mushtarak lafẓī (homonym). [1]</p>
                <p className="paragraph">An example in the English language is the word &ldquo;bat&rdquo;, which could be used for a baseball bat and the flying mammal.</p>
                <br/>
                <h4>Foundation Two: Linguists require definitions to point to the ontological reality of the defined</h4>
                <br/>
                <p className="paragraph">Muslim linguists consider a&nbsp;ta&rsquo;rīf&nbsp;(definition), which, for them, is synonymous with a&nbsp;ḥadd,&nbsp;as something that points to the essence and ontological reality of the defined. For example, Imam al-Zajjājī describes it as:</p>
                <p className="paragraph_arabic">الحدُّ هو الدَّالُ على حقيقةِ الشَّيءِ</p>
                <p className="paragraph_indented">The definition is that which points to the ontological reality of something. [2]</p>
                <p className="paragraph">Imam al-Zajjājī&rsquo;s statement encompasses definitions that are composed of the&nbsp;al-dhātiyāt&nbsp;(essentials) in addition to definitions composed of only necessary attributes without making a distinction between them. This is the case because prior to the introduction of logical definitions into Islamic sciences, Muslim scholars defined things freely as long as they captured the essence of the defined. And, ironically, this is a more logical way of defining things as even logicians agree defining the majority of things using&nbsp;al-dhātiyāt&nbsp;(essentials) is extremely difficult and perhaps even impossible. When we are referring to the literal meanings of words in language and in the Qur&apos;ān we are referring to the approach of linguists.</p>
                <p className="paragraph">On the other hand, for logicians, a ta&rsquo;rīf (definition) is a parent category that encompasses two distinct child categories. The first of these child categories is called ḥadd, a definition composed of essentials pointing to the ontological reality of the defined. The second is called a rasm, which is a description of the ontological reality of the defined. Regardless of whether the essence is used to point to the ontological reality of something or its description, both need to be inclusive of all of the particulars of the defined and exclusive of all the particulars that do not belong to the defined.&nbsp;</p>
                <p className="paragraph">Similar to linguists, for legal theorists,&nbsp;ḥadd&nbsp;and&nbsp;ta&rsquo;rīf&nbsp;are&nbsp;synonymous. Imam al-Maḥallī states:</p>
                <p className="paragraph_arabic"><b>(الحد)</b> عند الاصوليين ما يميز الشيء عما عداه كالمعرف عند المناطقة، و لا يميز كذلك إلا ما لا يخرج عنه شيء من أفراد المحدود ولا يدخل فيه شيء من غيرها</p>
                <p className="paragraph_indented">&ldquo;A ḥadd&rdquo; (a definition), for the Uṣūlīs (legal theorists), is that which separates something from anything other than it, similar to the ta&rsquo;rīf for the logicians. And nothing brings about this distinction except something that does not exclude any of the particulars of that which is being defined and does not include anything outside of it. [3]</p>
                <p className="paragraph">This may seem obvious to some readers, but we must establish it as foundational since it will be used later in the paper. For example, when linguists say &lsquo;human&rsquo;, they mean the ontological reality and essence of the human, what makes a human a human, and the only definition for a human is one that captures its ontological reality. Thus, if we were to define a human we would try to come up with a definition, both inclusive and exclusive, that defines his essence or comes very close to it.</p>
                <p className="paragraph">It must be reiterated that according to the scholars of all three disciplines, all definitions need to be inclusive of all of the particulars of the defined and exclusive of everything else, and the fact that the definitions of some scholars did not meet this requirement does not invalidate it. Furthermore, they need to point to the ontological reality of the defined, whether it is done through its&nbsp;jins&nbsp;(genus) and&nbsp;faṣl&nbsp;(difference), or its accidents, non-essential properties.</p>
                <p className="paragraph">Moreover, it is important to note that Muslim linguists have not derived this foundation from the teachings of Greek philosophers as some claim, but rather from the Qur&apos;ān. More specifically, from the occasion in which God (swt) taught Adam (as) all the names. At the end of this miraculous event, when Adam (as) was naming the nameables, he was naming their essence, not the concomitants and meanings they share. We will elaborate on why this distinction is important further into the paper. This should be enough to prove that the names of nouns and verbs are designated for the ontological realities of each respectively. Below is the story from the Qur&apos;ān:</p>
                <p className="paragraph_arabic">و عَلَّمَ آدَمَ الْأَسْمَاءَ كُلَّهَا ثُمَّ عَرَضَهُمْ عَلَى الْمَلَائِكَةِ فَقَالَ أَنْبِئُونِي بِأَسْمَاءِ هَٰؤُلَاءِ إِنْ كُنْتُمْ صَادِقِينَ - قَالُوا سُبْحَانَكَ لَا عِلْمَ لَنَا إِلَّا مَا عَلَّمْتَنَا ۖ إِنَّكَ أَنْتَ الْعَلِيمُ الْحَكِيمُ - قَالَ يَا آدَمُ أَنْبِئْهُمْ بِأَسْمَائِهِمْ ۖ فَلَمَّا أَنْبَأَهُمْ بِأَسْمَائِهِمْ قَالَ أَلَمْ أَقُلْ لَكُمْ إِنِّي أَعْلَمُ غَيْبَ السَّمَاوَاتِ وَالْأَرْضِ وَأَعْلَمُ مَا تُبْدُونَ وَمَا كُنْتُمْ تَكْتُمُونَ</p>
                <p className="paragraph_indented">And He (God) taught Adam (as) the names, all of them, and then He displayed them to the Angels and He said, inform me of the names of these if you are from the truthful - They said pure of all impurities are You; we have no knowledge except that which You taught us. Indeed You are the omniscient and the most wise. He said, O Adam, inform them of their names, and when he informed them of their names, He said, did I not tell you that I know the unseen of the heavens and the earth and I know that which you expose and that which you hide. (Qur&apos;ān 2:31-33)</p>
                <p className="paragraph">It is clear from the Qur&apos;ān that God (swt) taught Adam (as) only the names without the things to which those names belong. The fact that Adam (as) was able to name all the nameables without having seen them before, indicates that, as part of being taught the names, he was also taught the definitions of the names which pointed to the ontological reality of the nameables. Therefore, when the nameables were finally displayed to him, he was able to name every single one of them based on the definitions he was taught by God (swt). This is similar to having someone memorize a dictionary (names and definitions) and then asking them to name the objects that they see using the definitions that they have memorized.&nbsp;</p>
                <br/>
                <h4>Foundation Three: Words have both literal and metaphorical meanings</h4>
                <br/>
                <p className="paragraph">Virtually all Arab linguists are of the opinion that words have a designated literal meaning that points to their ontological reality. Furthermore, words may also be used metaphorically through a secondary designation. Linguists also believe that the Qur&apos;ān has both literal and metaphorical words. The Shāfiʽī scholar, Shaykh al-Islām Abū Isḥāq al-Shīrāzī, states in al-Lumaʽ:</p>
                <p className="paragraph_arabic">و الكلام المفيد ينقسم إلي حقيقة و مجاز و قد وردت اللغة بالجميع و نزل به القرآن</p>
                <p className="paragraph_indented">Beneficial speech divides into literal speech and figurative speech, and language has come with both and was revealed with both in the Qur&apos;ān. [4]</p>
                <p className="paragraph">Imam al-Subkī defines&nbsp;ḥaqīqa, a word used literally (for its primary designated meaning), as follows:</p>
                <p className="paragraph_arabic">الحقيقة لفظ مستعمل فيما وضع له ابتداء</p>
                <p className="paragraph_indented">The literal is a word that is used to indicate the primary meaning for which it was designated [3]</p>
                <p className="paragraph">And he defines a word used for its metaphorical meaning as follows:&nbsp;</p>
                <p className="paragraph_arabic">والمجاز اللفظ المستعمل بوضع ثان لعلاقة</p>
                <p className="paragraph_indented">And the figurative is the word which is used to indicate a secondary meaning due to a relationship [3]</p>
                <p className="paragraph"><span>Imam al-Subkī (ra) does not add the following condition to the definition of metaphor:</span></p>
                <p className="paragraph_arabic">مع قرينة مانعة عن إرادة ما وضع له أولا</p>
                <p className="paragraph_indented">With a hint that prevents the intention of its primary meaning</p>
                <p className="paragraph">Unlike the rhetoricians who add this additional condition, the Uṣūlīs (legal theorists) do not consider this a condition for the intention of the metaphorical meaning of a word. It is based on this understanding of the figure of speech that some Uṣūlīs such as Imam al-Shāfiʽī hold the position that it is possible for both primary (literal) and secondary (metaphorical) meanings of a word to be intended at the same time.&nbsp;</p>
                <p className="paragraph">With that said, there have been two very well-known scholars who have rejected the existence of metaphorical meanings for words. Shaykhul Islām Abū Isḥāq al-Shīrāzī states:</p>
                <p className="paragraph_arabic">و من الناس من أنكر المجاز في اللغة</p>
                <p className="paragraph_indented">From people, there are those who rejected figurative usage in language [4]</p>
                <p className="paragraph">Imam al-Subkī and Imam al-Maḥallī elaborate and tell us about who they were:</p>
                <p className="paragraph_arabic">(وهو) أي المجاز (واقع) في الكلام (خلافا للأستاذ) أبي إسحاق الاسفرايني (و) أبي علي (الفارسي) في نفيهما وقوعه (مطلقا)</p>
                <p className="paragraph_indented">&ldquo;And it&rdquo; meaning figurative speech &ldquo;occurs&rdquo; in language &ldquo;contrary to the teacher&rdquo;&nbsp;Abū Isḥāq&nbsp;al-Isfarā&rsquo;īnī&nbsp;&ldquo;and&rdquo;&nbsp;Abū Alī&nbsp;&ldquo;al-Fārsī&rdquo; in their negation of its occurrence &ldquo;completely&rdquo; [3]</p>
                <p className="paragraph">The nature of this difference and whether it is real or semantic, will be elaborated upon later in the paper. Assuming the difference is not semantic, the Qur&apos;ān clearly favors the majority position. The proof for the distinction between literal and metaphorical meanings of words is also in the aforementioned story of Adam (as). Imam al-Subkī says:</p>
                <p className="paragraph_arabic">(مسألة: قال ابن فورك و الجمهور اللغات توقيفية) أي وضعها الله تعالى فعبروا عن وضعه بالتوقيف لإدراكه به (علمها الله) عباده (بالوحي &hellip; و عزي إلى الأشعري) &hellip; واستدل لهذا القول بقوله تعالى و ((وعلم آدم الأسماء كلها))</p>
                <p className="paragraph_indented">&ldquo;Topic: Ibn Foorak and the majority have said that languages are finalized&rdquo; meaning they were designated by God (swt) and they used finalization to indicate His designation due to His encompassing knowledge of it &ldquo;God (swt) taught&rdquo; His servants &ldquo;through revelation &hellip; and it is attributed to Imam al-Ashʽarī&rdquo;... and he used, as proof for this opinion, the saying of God (swt) &lsquo;And God (swt) taught Adam (as) the names, all of them&rsquo;&nbsp;[3]</p>
                <p className="paragraph">The reason this becomes proof for the existence of figurative meanings, is because God (swt) tells us that Adam (as) named the nameables without any context, and as we know for a word&rsquo;s figurative meaning to be used or understood, it requires a context. Thus, we can conclude that because words have literal meanings, when they are used for anything other than their literal meanings, their use is figurative. And we know from experience that words are sometimes used for meanings other than their literal/primary meaning based on everyday experience.</p>
                <p className="paragraph">Shaykh Muḥammad al-Isfahānī says in his commentary of al-Maḥṣūl of Imam al-Rāzī, as narrated to us in the marginalia of Imam al-Bannānī:</p>
                <p className="paragraph_arabic">إِنْ عَلِمَ مَعْنا أُوْجِدَ فيه العلم لأن التعليم تفعيل و هو لا لإثبات الأثر بالنقل عن أئمة اللغة فيكون لإثبات العلم في آدم &hellip; و يلزم من ذلك التوقيف لأن الأسماء بأسرها توقيفية على ما صرح به في الآية</p>
                <p className="paragraph_indented">If [a learner] learns a meaning, then it was made to exist [in him or her] because [the verb] taʽlīm [is on the scale of] tafʽīl [which often requires an object], and that knowledge which has come into existence is not due to the establishment of a reference from the scholars of language, therefore, it is because of the establishment of knowledge in Adam (as).&rdquo; &hellip; &ldquo;An that necessitates finalization [of language by God], because indeed all names without exception are finalized [come from God] as He explicitly states in the verse [3]</p>
                <p className="paragraph">The tafsīrs of Ibn ʽAbbās (ra) and other great exegesists of this verse seal this debate in favor of the majority position. It is mentioned in the tafsīr of Imam al-Qurṭubī that:</p>
                <p className="paragraph_arabic">قال ابن عباس وعكرمة وقتادة ومجاهد وابن جبير: علمه أسماء جميع الأشياء كلها جليلها وحقيرها</p>
                <p className="paragraph_indented">Ibn ʽAbbās and ʽIkrima and Qatāda and Mujāhid and Ibn Jubair have said, &ldquo;He taught him the names of all things, be it small or large&rdquo;</p>
                <p className="paragraph">Imam al-Qurṭubī continues, stating:</p>
                <p className="paragraph_arabic">قال ابن خويز منداد: في هذه الآية دليل على أن اللغة مأخوذة توقيفا، وأن الله تعالى علمها آدم عليه السلام جملة وتفصيلا. وكذلك قال ابن عباس : علمه أسماء كل شيء حتى الجفنة والمحلب.</p>
                <p className="paragraph_indented">Ibn Khuwaiz Mindād has said, &ldquo;In this verse, there is proof that language is taken as-is [from God], and that God (swt) taught them to Adam as a whole and in detail.&rdquo; And that is what Ibn ʽAbbās (ra) said, &ldquo;God (swt) taught him everything even the bowl and the milk churn.&rdquo;</p>
                <br/>
                <h4>Foundation Four: It is possible for both the literal and the figurative meanings of a word to be intended at the same time</h4>
                <br/>
                <p className="paragraph">As explained in foundation two, in the science of Uṣūl (Islamic legal theory), the intention of the figurative meaning of a word does not negate the intention of its literal meaning. According to Imam al-Shāfiʽī, a master of the Arabic language, it is possible for both the literal and metaphorical meanings of a word to be intended. Imam al-Maḥallī says in his commentary on Jamʽul Jawāmiʽ of Imam Tāj al-Dīn al-Subkī:</p>
                <p className="paragraph_arabic">(مسألة: المشترك يصح إطلاقه على معنييه معا مجازا وعن الشافعي &hellip; هو حقيقة) &hellip; (و في الحقيقة والمجاز) هل يصح أن يرادا معا باللفظ الواحد كما في قولك رأيت الأسد و تريد الحيوان المفترس والرجل الشجاع (الخلاف) في المشترك &hellip; و على الصحة يكون مجازا أو&nbsp;حقيقة و مجازا باعتبارين على قياس ما تقدم عن الشافعي و غيره.&nbsp;و يحمل عليهما إن قامت قرينة على إرادة المجاز مع الحقيقة كما حمل الشافعي الملامسة في قوله تعالى (( أو لامستم النساء)) على الجس باليد و الوطء</p>
                <p className="paragraph_indented">&ldquo;Topic: The homonym: it is permissible for it to be used to mean both of its meanings at the same time, metaphorically&rdquo; [meaning its use for both of its meanings at the same time is metaphorical since by default it is designated to mean one meaning at a time], &ldquo;and it has been narrated from Imam al-Shāfiʽī that it is literal&rdquo; [meaning if both meanings of a homonym are used at the same time, this type of use is literal based on the opinion that a homonym is not limited to one meaning at a time by default])...&rdquo; And in the literal and metaphorical&rdquo; is it permissible for them to be intended at the same time in a single word, such as saying I saw a lion, and meaning by it the hunting animal and the brave man &ldquo;the same difference exists&rdquo; that existed in the homonym [meaning the difference that existed in the homonym exists in intending the literal and metaphorical meanings of a word at the same time as well] &hellip; and if permissible it becomes metaphorical [meaning this type of use is metaphorical based on the opinion that only one can be interned at a time by default] or literal and metaphorical from two different aspects [meaning the literal meaning is intended literally and the metaphorical metaphorically based on the opinion that a word is not limited to only one at a time by default], based on what was mentioned from Imam al-Shāfiʽī and others. The word is considered to mean both if an indication of the intention of the metaphorical meaning and literal meaning exists. Similar to how Imam al-Shāfiʽī considered touching, in the saying of God (swt) ((And when you touch the women)), to mean both touching by hand and intercourse [3]&nbsp;</p>
                <p className="paragraph">In short, the position favored by Shāfiʽī scholars, at the head of the Imam al-Shāfiʽī, is that both the literal and metaphorical meanings of a word can be meant at the same time. And this foundation, which allows us to better understand the ʽAqīda of the Salaf, is one that is often neglected in the discourse on attributes. Without this foundation and/or foundation five the creed of the Salaf cannot be understood correctly, which leads to extremely incoherent arguments.&nbsp;</p>
                <p className="paragraph">In the case of the attributes in question, the unknown literal meaning may be intended to affirm the attribute and the metaphorical meaning may be intended so that we take away a relevant meaning from the verse. Similar to one conveying that there is a brave man on the battlefield and a real lion that has smelled blood using the statement &ldquo;there is a lion on the battlefield&rdquo;. &nbsp;</p>
                <br/>
                <h4>Foundation Five: It is possible for both the literal and the implied meanings of a sentence to be intended</h4>
                <br/>
                <p className="paragraph">There is a subcategory of figurative speech called&nbsp;kīnāya&nbsp;which allows for the intention of both a sentence&rsquo;s literal and implied meanings. In the passage below, the Shāfiʽī linguist al-Khaṭīb al-Qazwīnī defines&nbsp;kīnāya, which is followed by the commentary of Imam al-Taftāzānī:</p>
                <p className="paragraph_arabic">(لفظ أريد به لازم معناه مع جواز إرادته معه) أي إرادة ذلك المعنى مع لازمه كلفظ طويل النجاد و المراد به طول القامة مع جواز أن يراد حقيقة طول النجاد أيضا.</p>
                <p className="paragraph_indented">&ldquo;A statement by which the concomitant of its meaning is intended, with the permissibility of the intention of its literal meaning with it&rdquo; meaning the intention of the literal meaning along with its concomitant, such as the statement &ldquo;long is his scabbard&rdquo; and it is intended by it tallness with the permissibility of the intention of longness of the scabbard as well [5]&nbsp;</p>
                <p className="paragraph">And Imam al-Subkī defines it in his book on Uṣūl, Jamʽul Jawāmiʽ as:</p>
                <p className="paragraph_arabic">(مسألة: الكناية لفظ استعمل في معناه مرادا منه لازم المعنى فهي حقيقة. فإن لم يرد المعنى و إنما عبر بالملزوم عن اللازم فهو مجاز) لأنه استعمل في غير معناه أي الأول.</p>
                <p className="paragraph_indented">&ldquo;Topic: al-kīnāya is a statement used for its [original] meaning, being intended from it, its concomitant, and it would be considered literal. And if it&nbsp;[the original meaning]&nbsp;is not intended and the accompanied is not used except for the conveying of its concomitant, then it is metaphorical&rdquo; Because it is used in a meaning other than its primary meaning. [3]</p>
                <br/>
                <h4>Foundation Six: It is possible to understand the implied meaning but not the literal</h4>
                <br/>
                <p className="paragraph">It is possible, as evident when learning a new language, not to know the literal meaning of a word, yet understand its implied meaning based on the context. This, then, leads one to understand the sentence in which the word is used. This applies to both monosemous words and homonyms, the unknown meaning of which is one of the attributes of God (swt). It is possible to understand the implied meaning of an attribute but not its literal meaning because different words, and as an extension, different meanings of a homonym can have the same implied meaning, such as a hand, a sword, and an army all implying strength.&nbsp;</p>
                <p className="paragraph"><span>One may ask &ldquo;how is it possible for humans to not understand the meaning of a particular word?&rdquo; We can find the answer in the following statement of Imam al-Subkī:</span></p>
                <p className="paragraph_arabic">فعلم وجوب سبق الوضع وهو اتفاق لا الاستعمال وهو المختار</p>
                <p className="paragraph_indented"><span>And it was made known [in the previous sections of his book] the requirement of the pre-designation [of meanings for words], and that [requirement] is agreed upon, not the previous usage, and that is the preferred position. [3]</span></p>
                <p className="paragraph">In the passage above, Imam al-Subkī introduces the reader to the distinction between the designation of meanings for words and the use of those words for those meanings. Furthermore, he elaborates that the designation of meanings for words does not necessitate the use of those words for those meanings.&nbsp;</p>
                <p className="paragraph">The reason we know the literal meanings of words is because they were used at some point by Adam (as) to communicate with his progeny. However, the use of all words that God (swt) taught Adam (as) was not a must, unlike their designation. Thus, it is possible that a word was designated a meaning by God (swt) and taught to Adam (as) but never used by Adam (as) on earth and as an extension, his progeny, the humans. Certain words may not have been used by Adam (as) since the nameables were not something humans were going to be exposed to in this world, such as the attributes of God (swt) the known meanings of which are anthropomorphic. This leaves room for humans not to know the literal meaning of certain words in the Qur&apos;ān, yet understand their implied meaning.&nbsp;</p>
                <p className="paragraph">And if it is asked, &ldquo;if the primary/literal meaning is not known, how do we derive the relationship between the literal and metaphorical meanings?&rdquo; The answer is from the context. Knowing the relationship between the literal and the metaphorical does not always necessitate knowing the literal meaning. For example, what tells us about the implied meaning of the following verse is what comes after it. God (swt) says:</p>
                <p className="paragraph_arabic">بل يداه مبسوطتان</p>
                <p className="paragraph_indented">But rather His yads are wide open</p>
                <p className="paragraph">Then, immediately after, He (swt) states:</p>
                <p className="paragraph_arabic">ينفق كيف يشاء</p>
                <p className="paragraph_indented">He spends/gives as He wishes</p>
                <p className="paragraph">The fact that God (swt) couples this statement with spending indicates that yad for God (swt), in this context, is an attribute that implies giving and generosity. Thus, generosity can be meant by yad metaphorically, while its, also intended, literal meaning that points to its ontological reality, remains unknown. Alternatively, we can treat this verse and all other similar verses as&nbsp;kīnāya&nbsp;by demonstrating that the intention of its unknown literal meaning does not negate its concomitant, implied meaning.</p>
                <p className="paragraph">Therefore, knowing and affirming the metaphorical meaning of&nbsp;yad&nbsp;(care or power) in the previous and following verses does not negate the intention of its unknown literal meaning. Furthermore, knowing and affirming the verse&rsquo;s implied meaning (the honoring of Adam (as) by being directly created by God (swt)) does not oppose the literal meaning of the verse. In other words, the known meaning of&nbsp;yad&nbsp;and the unknown meaning of&nbsp;yad&nbsp;can have the same metaphorical and implied meaning.</p>
                <p className="paragraph_arabic">قَالَ يَٰٓإِبْلِيسُ مَا مَنَعَكَ أَن تَسْجُدَ لِمَا خَلَقْتُ بِيَدَىَّ</p>
                <p className="paragraph_indented">God (swt) said, O Iblīs, what prevented you from prostrating for that which I created with my yaday? (Qur&apos;ān 38:75)</p>
                <br/>
                <h4>Foundation Seven: The particulars of a universal have the same ontological reality</h4>
                <br/>
                <p className="paragraph">The shortest and most convincing proof for this foundation is the fact that if the particulars of a universal did not share the same ontological reality, God (swt) would not use universals pre-eternally, at a time when the particulars did not exist yet, to inform us about the particulars such as:&nbsp;</p>
                <p className="paragraph_arabic">إذ قال ربك للملائكة إنى خالق بشرا من طين</p>
                <p className="paragraph_indented">When your Lord said to the angels I am indeed the creator of a human from clay (Qur&apos;ān 38:71)</p>
                <p className="paragraph">The verse indicates that the concept of a human obviously existed pre-eternally in the knowledge and speech of God (swt) and it would be blasphemy to argue God (swt) did not know the ontological reality of the uncreated human. Another example is:</p>
                <p className="paragraph_arabic">وَمَا خَلَقْتُ ٱلْجِنَّ وَٱلْإِنسَ إِلَّا لِيَعْبُدُونِ</p>
                <p className="paragraph_indented">I did not create the jinn and the human except so that they worship me (Qur&apos;ān 51:56)</p>
                <p className="paragraph">This should be enough to demonstrate that the particulars of a universal all share the same ontological reality (essence). However, if the reader would like to know more about universals, they are encouraged to read the rest of this section.</p>
                <p className="paragraph">Before we can discuss universals, we first need to discuss the different types of essences (<em>māhiyyat</em>) which are:</p>
                <ol>
                    <li className="paragraph_arabic">
                        بشرط شيء (الماهية المخلوطة)
                    </li>
                    <li className="paragraph_arabic">
                        بشرط لا شيء (الماهية المجردة)
                    </li>
                    <li className="paragraph_arabic">
                        لا بشرط شيء (الماهية المطلقة)
                    </li>
                </ol>
                <ol>
                    <li className="paragraph">
                        The essence conditioned with something (The&nbsp;mixed essence)
                    </li>
                    <li className="paragraph">
                        The essence conditioned with nothing (The stripped essence)
                    </li>
                    <li className="paragraph">
                        The essence without a condition (The unrestricted essence) [6]
                    </li>
                </ol>
                <p className="paragraph">The essence conditioned with something is that which is coupled with external accidents, and by external, we mean any attribute that exists outside and irrespective of anyone&rsquo;s consideration, any attribute that exists regardless of whether it is imagined or not. Thus, including things such as being a species for a human in the mind, along with the greenness of a leaf on a tree, since neither require us to think about them (consider them) to exist. But this does not include non-existent things such as a flying cat since such a trait needs to be considered (imagined) to exist mentally. [6]</p>
                <p className="paragraph">Like its accidents, the essence conditioned with something exists externally, meaning it exists irrespective of the consideration of a considerer.&nbsp;Therefore, four pens described by evenness which is both an intramental and extramental attribute, the fire described by heat, which is an extramental attribute only, a human described by being a specie, which is an intramental attribute only, and the fictional bird,&nbsp;Anqa,&nbsp;described by beauty in Islamic literature, all fall under the category of essences conditioned with something, as their accidents are with them regardless of whether they are considered or not.</p>
                <p className="paragraph">The essence conditioned with nothing is the essence that is not coupled with any accidents leading to the lack of its intramental and extramental existence since an essence without mental and external accidents does not exist.</p>
                <p className="paragraph">The essence without a condition is the general/unrestricted essence that encompasses the first two; however, as we mentioned, only one of its subclasses exists, the mixed essence, such as a human called Aḥmad. Human here is the unrestricted essence. The unrestricted essence, when part of the mixed essence, is also called a universal since it describes all of the mixed essences which are its particulars. For example, both Aḥmad and John are mixed essences that are subject to their unrestricted essence of humanness. And this brings us to the topic of universals.&nbsp;</p>
                <p className="paragraph">There are three types of universals:</p>
                <ol>
                    <li className="paragraph_arabic">
                        كلي منطقي
                    </li>
                    <li className="paragraph_arabic">
                        كلي طبيعي
                    </li>
                    <li className="paragraph_arabic">
                        كلي عقلي&nbsp;=&nbsp;كلي منطقي&nbsp;+&nbsp;كلي طبيعي
                    </li>
                </ol>
                <ol>
                    <li className="paragraph">
                        The logical universal
                    </li>
                    <li className="paragraph">
                        The natural universal
                    </li>
                    <li className="paragraph">
                        The intellectual universal = the logical + the natural
                    </li>
                </ol>
                <p className="paragraph">For the most part the scholars agree regarding the logical and mental universals being purely intramental or secondary intelligible (maʽqūl ṯānī). The dispute over intramental or extramental existence pertains to the natural universal such as the human. Does the human exist outside of our minds through the existence of its particulars (what we today call moderate realism)? Or is it a concept extracted and conceptualized by the intellect from the particulars (what we today call conceptualism)? Conceptualism states that only particulars exist extramentally and universals can be said to exist, but only as concepts in the mind.&nbsp;</p>
                <p className="paragraph">There is another theory called nominalism which Muslim theologians do not discuss. Nominalism states that universals or general ideas are mere names without any corresponding reality. Below we tie back the concept of universals as discussed by&nbsp;mutakallimūn&nbsp;to the concept of essences and show how sharing the same ontological reality does not depend on the existence of universals extramentally as some have implied.</p>
                <p className="paragraph">The natural universal&rsquo;s existence is through the existence of its particulars since the particular is the restricted/mixed essence, which includes by definition the unrestricted essence. For instance, the unrestricted pen (not restricted by belonging to Zaid) in the example of the &ldquo;pen of Zaid&rdquo; is necessarily still part of the &ldquo;pen of Zaid&rdquo;. However, this does not mean the unrestricted pen and the specific pen have two separate existences and can be separated.</p>
                <p className="paragraph">With that said, there are two opinions regarding what the external existence of the natural universal means, to which we have already alluded. The first is the opinion of a group of philosophers called&nbsp;Mashā&rsquo;īyīn&nbsp;(Peripatetics) who argued that the usage of &ldquo;existence&rdquo; to describe the natural universal is literal and it (the existence of the natural universal) is through its particulars, at the head of them was Aristotle (moderate realism). The second opinion is held by the&nbsp;Ishrāqīyīn&nbsp;(Illuminationists) who believed that the use of &ldquo;existence&rdquo; for the natural universal is metaphorical since it is a concept deduced by the intellect and what is meant by its existence is the existence of its particulars (conceptualism). Shaykh ʽAbdul Karīm al-Mudarris states regarding the two opinions in his commentary of al-Faḍīla of Shaykh Mawlawī:</p>
                <p className="paragraph_arabic">(فكلي طبيعي) نسبته إلى الطبيعة بمعنى الخارج لكون جزئياته موجودة فيه و يسمى العارض كليا منطقيا لأن بحث المناطقة إنما هو عنه و مجموع المعروض و العارض كليا عقليا إذ لا وجود له إلا فيه. و لما اعترض على ما اشتهر من أن الكلي الطبيعي موجود في الخارج بانه موصوف بالكلية وهي مانعة عنه أجاب بقوله (ووجدا من ذلكم) المعروض و العارض،(معروضها) أي معروض العارض، أنث الضمير باعتبار الكلية (مجردا) عن عارض الكلية و ما يتبعها من الجنسية و النوعية المانعة عن الوجود الخارجي، و المعروض الموجود في الخارج المجرد عن الموانع هو الماهية المخلوطة اعني الاشخاص ثم ظاهر قوله - وجد معروضها - أنه أختار مذهب المشائيين القائلين بوجود الكلي الطبيعي في الخارج لكن بعين وجود الأشخاص لا بوجود ممتاز لا الاشراقيين و المتكلمين القائلين بان الطبائع غير موجودة فيه قطعا، و إنما هي إمور إعتبارية ينتزعها العقل من الهويات و الاشخاص الخارجية بسبب التنبه لما بينهما من المشاركات و المباينات.</p>
                <p className="paragraph_indented">&ldquo;The natural universal&rdquo;&nbsp;its association to nature, meaning outside, is due to its particulars existing in it [outside, extramentally] and the describer (ʽāriḍ) is called a logical universal since it is a topic of discussion amongst logicians. And both the described (maʽrūḍ) and describer (ʽāriḍ) combined are called the intellectual universal since it has no existence except in it [the intellect]. And when an objection was made against the popular opinion that the natural universal exists extramentally, since [reasoning for the objection] it is described as being a universal and it [being a universal] prohibits extramental existence, he [al-ʽAllāma Mawlawī] answered [the objection] by saying &ldquo;and the existent from them&rdquo; the described and describer &ldquo;is the described&rdquo; meaning the described by the describer, the pronoun was made feminine referring to universality &ldquo;stripped&rdquo; from the attribute of universality and that which succeeds it, such as being a genus or species that prevent extramental existence. And the described stripped from preventers is the mixed essence meaning the particulars. Furthermore, the apparent meaning of his statement &lsquo;the described is existent&rsquo; indicates that he chose the position of peripatetics who say the natural universal exists extramentally, but through the existence of its particulars, not a distinct existence. Not the position of the Illuminationists and mutakallimūn who state that naturals are non-existent extramentally undoubtedly, but rather they are relative concepts deduced by the intellect from essences and particulars that exist extramentally due to the observation of that which is between them, be they commonalities or differences.&nbsp;[6]</p>
                <p className="paragraph">Al-Sayyid Sharīf al-Jurjānī elaborates on why Muslim theologians chose the conceptualist position. He states:</p>
                <p className="paragraph_arabic">ثم إنه بَيِنٌ أن تركب الشخص المعين من الماهية و التعين إنما هو بحسب الذهن دون الخارج، فقال (و اعلم أن النسبة الماهية إلى المشخصات كنسبة الجنس إلى الفصول) فكما أن الجنس مبهم في العقل يحتمل ماهيات متعددة و لا تعين لشيء منها إلا بانضمام فصل إليه، و هما متحدان ذاتا و جعلا و وجودا في الخارج، و لا يتمايزان إلا في الذهن، كذلك الماهية النوعية تحتمل هويات متعددة و لا تعين لشيء منها إلا بمشخص ينضم إليها، وهما متحدان في الخارج ذاتا و جعلا و وجودا، و متمايزان في الذهن فقط. فليس في الخارج موجود هو الماهية الانسانية مثلا و موجود آخر هو التشخص حتى يتركب منهما فرد منها، و إلا لم يصح حمل الماهية على أفرادها، بل ليس هناك إلا موجود واحد أعني الهوية الشخصية إلا أن العقل يفصلها إلى ماهية نوعية و تشخص كما يفصل الماهية النوعية إلى الجنس و الفصل.</p>
                <p className="paragraph_indented">Furthermore, it is clear that the composition of the specific individual from the quiddity and the specification/individuation is indeed in the mind and not outside of it, and he (Imam al-&rsquo;ījī) said, &ldquo;and know that the association of quiddity with particulars is similar to the association of the genus with the difference&rdquo; thus, similar to how the genus is ambiguous in the intellect and could apply to multiple quiddities that are not specified except by the attachment of a difference to them [e.g. the rational animal], and are one, in quiddity, creation, and existence extramentally and are only distinguishable in the mind, the special quiddity, also, is applicable to multiple identities and is not chosen for any of them except by using a distinguished particular that attaches to it. And they are one, in quiddity, creation, and existence extramentally and are only distinguishable in the mind. Thus, there is no existent being extramentally that is the human quiddity for example, and another existent being that is the individuation so that a particular gets composed of them. If this was not the case, the predication of quiddity upon its particulars would not be possible [because, then, the quiddity would be a part of the particular. Just like how we cannot say a car is wheels]. Rather, there is nothing except a single being, meaning the individual identity. However, the intellect separates the special quiddity and individuation similar to how the special quiddity is divided into the genus and the difference. [7]</p>
                <p className="paragraph">To understand the above explanation, it is upon the reader to learn about the 5 predicables (genus, species, difference, property, and general accident) which are discussed heavily in the passage above. However, as implied previously, this paper can be understood even if the last part of Foundation 7 is skipped.</p>
                <p className="paragraph">Some readers may have noticed the absence of Platonic realism in the division presented. The types mentioned were Aristotle&rsquo;s moderate realism, conceptualism, and nominalism. Platonic realism is discussed by mutakallimūn, but either as a concept contrary to the division of quiddities presented, as evident in al-Mawāqif of Imam al-&rsquo;ījī, or as something totally different, thus, not belonging under the same topic, as seen in the Sharḥ al-Maqāsid of Imam al-Taftāzānī. The latter is also alluded to by Imam al-&rsquo;ījī in al-Mawāqif. Imam al-&rsquo;ījī (ra) states:&nbsp;</p>
                <p className="paragraph_arabic">قال الافلاطون الماهية المجردة يوجد من كل نوع فرد مجرد أزلي أبدي قابل للمتقابلات و احتج عليه بأن الانسان قابل للمتقابلات و إلا لم تعرض له فيكون مجردا عن الكل و أنت علمت أن المجرد لا وجود له في الخارج بل يمتنع أن يكون موجودا فيه فهذا المدعى باطل قطعا.&nbsp;</p>
                <p className="paragraph_indented">Plato stated that the stripped essence [exists]. A particular stripped [of external accidents] exists eternally and forever for every species, receptive to many reciprocals [individuation]. And he used, as evidence, the fact that the human is receptive to many reciprocals since if that was not the case, its particulars would not become manifest, thus, it would be stripped of all [end of Plato&rsquo;s statement]. And you already know that the stripped essence has no existence externally, rather, it is impossible for it to exist externally; therefore, this claim is definitely invalid. [7]</p>
                <p className="paragraph"><span>Imam al-&rsquo;ījī (ra) continues:</span></p>
                <p className="paragraph_arabic">و إن عني به معنى آخر مثل ما أوله به بعض المتأخرين من أن لكل نوع أمرا مجردا يدبره و هو الذي يسميه رب النوع فذلك &nbsp;بحث آخر.</p>
                <p className="paragraph_indented">And if he means by it a different thing such as the interpretation of some of the later scholars that for every species there is something stripped that controls and cares for it and that is what he calls the master of species, then that becomes a different topic. [7]</p>
                <p className="paragraph">What makes the conclusion that this position of Plato does not belong to the discourse of universals very likely to be accurate is the fact that his theory pertains only to types or species (nawʽ) while the problem of universals encompasses all types of universals. This is also the opinion held by Imam al-Taftāzānī and other Muslim theologians, who interpret the statement of Plato regarding the existence of universals in a manner that is not in contradiction with conceptualism and the classification of quiddities presented. Imam al-Taftāzānī states:</p>
                <p className="paragraph_arabic">و ما نسب إلى أفلاطون من المثل ليس قولا بوجود المجردة بل بوجود الأنواع في علم الله تعالى</p>
                <p className="paragraph_indented">And that which is attributed to Plato pertaining to the form (al-maṯal) is not an affirmation of the existence of the stripped&nbsp;quiddity, but rather, it is an affirmation of the existence of species in the knowledge of God the most high [8]</p>
                <p className="paragraph">He narrates this position from Imam al-Fārābī. He states:</p>
                <p className="paragraph_arabic">قال الفارابي في كتاب الجمع بين رأي أفلاطون و أرسطو أنه إشارة إلى أن للموجودات صورا في علم الله تعالى باقية لا تتبدل ولا تتغير</p>
                <p className="paragraph_indented">Al-Fārābī has said in the book Reconciling the Positions of Plato and Aristotle that [the form] is a hint to the fact that existent beings have eternal forms in the knowledge of God, which are not replaced nor changed [8]</p>
                <p className="paragraph">This understanding of what Plato wrote, is perfectly in line with the simple proof we provided at the beginning of this foundation using the verses of the Qur&apos;ān which affirm the existence of universal in the knowledge of God (swt) before particulars were created, such as:</p>
                <p className="paragraph_arabic">إِذْ قَالَ رَبُّكَ لِلْمَلَائِكَةِ إِنِّي خَالِقٌ بَشَرًا مِنْ طِينٍ</p>
                <p className="paragraph_indented">When your Lord said to the angels, I will create a human from clay. (Qur&apos;ān 38:71)</p>
                <p className="paragraph">So the concept of a human has existed pre-eternally. And based on this interpretation, we can argue that Plato was most likely a conceptualist. With this, we can conclude that there are two possible reasons why Platonic realism was not considered in the problem of universals by Muslims:</p>
                <ol>
                    <li className="paragraph">
                        Platonic realism is an invalid position; therefore, the notion that it may be a theory explaining universals is rejected.&nbsp;
                    </li>
                    <li className="paragraph">
                        Platonic realism is simply an affirmation of the existence of universals in the pre-eternal knowledge of God
                    </li>
                </ol>
                <p className="paragraph">It is important to reiterate that the position supported by the Qur&apos;ān mentioned at the beginning of this foundation, which approves the explanation of Plato&apos;s position given by al-Fārābī and others, does not refute moderate realism nor conceptualism; however, it does reject nominalism. Its rejection of nominalism is due to the fact that stating universals are merely names and do not have a reality goes against the verses of the Qur&apos;ān in which universals are used to point to the ontological reality of the nameables that are not yet created. God makes it very clear that universals are not merely names without any realities. As further proof, God (swt) says:</p>
                <p className="paragraph_arabic">خلق الانسان ضعيفا</p>
                <p className="paragraph_indented">The human was created weak.&nbsp;(Qur&apos;ān 4:28)</p>
                <p className="paragraph">Al, what we call the article of the genus in Arabic, is used in this verse to describe the human being, which clearly shows that God does affirm the same ontological reality for humans. The weak human that was always known in the knowledge of God, the one regarding whom He said:</p>
                <p className="paragraph_arabic">مِنْ نُطْفَةٍ خَلَقَهُ فَقَدَّرَهُ</p>
                <p className="paragraph_indented">From a drop of sperm He created him and thereupon determined his nature (Qur&apos;ān 80:19)</p>
                <p className="paragraph">Based on this verse, we can conclude that God (swt) created the human the particulars of which share the same ontological reality; they all come from sperm. The concept of Insān was in the knowledge of God (swt) before any human was created. Simply because the universal &ldquo;human&rdquo; exists only intramentally does not mean we don&apos;t all share an ontological reality that existed in the knowledge of God and was determined by Him pre-eternally. To knowingly claim otherwise is to deny the eternal knowledge of God (swt).&nbsp;</p>
                <p className="paragraph">Therefore, we can conclude that the correct position when it comes to the problem of universals is the conceptualist position, which does not call for the rejection of the existence of a single literal meaning of words nor does it oppose shared ontological realities; in fact to the contrary, it necessitates both. For a believer, all literal meanings of words were taught to Adam (pbuh) by God (swt) and the common ontological existences of the nameables for which those words were designated were willed by Him. That is why Adam was able to match the names with the nameables even though God (swt) had not shown him the nameables when teaching him the names.&nbsp;</p>
                
                <Link to={"/articles/coherence-of-creed-two"}>
                    <div>
                        <p className="paragraph">The Coherence of Creed - Part Two - The ʽAqīda of the Four Imams</p>
                    </div>
                </Link>
                
                <h6 align="left"><br /><i>By Souhayl Maronesy</i></h6>
                <h6 align="left"><i>11/30/2022</i></h6>
                <h5 align="left"><br/>References:</h5>
                <p className="paragraph"><br/><i>[1] ʿAbd al-Raḥmān Ibn Aḥmad al-’ījī, Sharḥ al-ʿAḍud, 2 vols. (Cairo: Dār Ibn Ḥazm, 2016), 1:160</i></p>
                <p className="paragraph"><i>[2] Abū al-Qāsim al-Zajjājī, Al-’īḍāḥ fī ʿIlm al-Naḥw, (Beirut: Dār al-Nafā’is, 1979), 6</i></p>
                <p className="paragraph"><i>[3] al-Bannānī, Ḥāshiyah al-ʿAllamah al-Bannānī, 2 vols. (Beirut: Dār al-Kutub al-’lmīyyah, 2013) 2:372, 445, 487</i></p>
                <p className="paragraph"><i>[4] Muḥammad Yāsīn al-Fādānī, Buġyah al-Mushtāq fi Sharḥ al-luma’, (Beirut: Dār Ibn Kaṯīr, 2017), 53</i></p>
                <p className="paragraph"><i>[5] Saʿd al-dīn al-Taftāzānī, Mukhtaṣar al-Maʿānī, (Istanbul: Dār al-Shifa’, 2017), 52, 558</i></p>
                <p className="paragraph"><i>[6] ʿAbd al-Karīm al-Mudarris, Al-Wasīla fi Sharḥ al-Faḍīla, (Baghdad, al-’Irshād publishing, 1972), 167-175</i></p>
                <p className="paragraph"><i>[7] al-Sayyid Sharīf al-Jurjānī, Sharḥ al-Mawāqif, 3 vols. (Cairo: Sharikah al-Quds, 2018), 1:266, 291</i></p>
                <p className="paragraph"><i>[8] Imam al-Taftāzānī, Sharḥ al-Maqāsid, 3 vols. (Beirut: Dār al-Kutub al-’lmīyyah, 2011), 1:228-229</i></p>
            </div>
        )
    }
}
