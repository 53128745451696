import React, { Component } from 'react'
import MetaTags from 'react-meta-tags';
import {
    Link
  } from "react-router-dom";
import {Helmet} from "react-helmet-async";

export default class CoherenceOfCreedTwo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            like: 0,
            dislike: 0
        }
    }

    likeIncrement = () => {
        this.setState({ like: this.state.like + 1})
    }

    dislikeIncrement = () => {
        this.setState({ dislike: this.state.like + 1})
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "The Coherence of Creed - Part Two - The ʽAqīda of the Four Imams"
    }

    render() {
        return (
            <div className="webpage_content">
                {/* <MetaTags>
                    <title>The Coherence of Creed - Part One - Foundations</title>
                    <meta property="og:title" content="The Coherence of Creed - Part One - Foundations" />
                    <meta property="og:image" content="../../assets/foundations.png" />
                </MetaTags> */}
                <Helmet>
                    <title>content="The Coherence of Creed - Part Two - The ʽAqīda of the Four Imams"</title> 
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content="https://alkalam.org/articles/coherence-of-creed-one" />
                    <meta property="og:image" content="../../assets/creed_four_imams.png" />
                </Helmet>
                <div className="like_dislike">
                    <div className='like'>
                        <input onClick={this.likeIncrement} type="image" src="../../../assets/article_dislike1.png" alt="dislike" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.like}</div> */}
                    <div className='dislike'>
                        <input onClick={this.dislikeIncrement} type="image" src="../../../assets/article_like1.png" alt="like" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.dislike}</div> */}
                </div>
                <img className="poster" src="../../assets/creed_four_imams.png" alt="Alkalām Research Institute (ARI)"/> {' '}
                <br/><br/><br/>
                <h3>The Coherence of Creed - Part Two - The ʽAqīda of the Four Imams</h3>
                <br/>
                <h5>In the name of Allāh, the one and only God</h5>
                <br />
                <p className="paragraph">Let us now determine whether the four Imams deviated from the previously established foundations. It is only fitting that we begin this section with one of the statements of Imam al-Shāfiʽī (ra) relevant to the topic at hand. He states:</p>
                <p className="paragraph_arabic">آمنت بالله، وبما جاء عن الله، على مراد الله، وآمنت برسول الله، وبما جاء عن رسول الله، على مراد رسول الله</p>
                <p className="paragraph_indented">I believed in God and that which has come from God upon the meaning intended by God. And I believed in the messenger of God and that which has come from the messenger of God upon the meaning intended by the messenger God. [9]</p>
                <p className="paragraph">This is a belief, the origins of which are complete surrender and extreme humility, the two qualities of an unwavering servant of God. May God make both the writer and the reader amongst them. One may ask what is the point of explicitly mentioning what Imam al-Shāfiʽī mentions? Do we not always defer the knowledge of everything to God and say God knows best? This is a reasonable question, which, ironically, partially answers itself. How? The question tells us that Imam al-Shāfiʽī is not referring to verses and statements, the meanings of which are clear. He is referring to verses and statements, the meanings of which are ambiguous and disputed. Imam al-Shāfiʽī&rsquo;i is referring to the verses of the Qur&apos;ān and statements of the Prophet (pbuh), the literal meanings of which only God (swt), His Prophet (pbuh), and His chosen&nbsp;awlīyā&nbsp;(allies) know. Imam al-Shāfiʽī is saying what&nbsp;al-rāsikhūna fil ʽilm (the well versed in knowledge) say in the following verse from the Qur&apos;ān:</p>
                <p className="paragraph_arabic">هُوَ الَّذِي أَنْزَلَ عَلَيْكَ الْكِتَابَ مِنْهُ آيَاتٌ مُحْكَمَاتٌ هُنَّ أُمُّ الْكِتَابِ وَأُخَرُ مُتَشَابِهَاتٌ ۖ فَأَمَّا الَّذِينَ فِي قُلُوبِهِمْ زَيْغٌ فَيَتَّبِعُونَ مَا تَشَابَهَ مِنْهُ ابْتِغَاءَ الْفِتْنَةِ وَابْتِغَاءَ تَأْوِيلِهِ ۗ وَمَا يَعْلَمُ تَأْوِيلَهُ إِلَّا اللَّهُ ۗ وَالرَّاسِخُونَ فِي الْعِلْمِ يَقُولُونَ آمَنَّا بِهِ كُلٌّ مِنْ عِنْدِ رَبِّنَا ۗ وَمَا يَذَّكَّرُ إِلَّا أُولُو الْأَلْبَابِ</p>
                <p className="paragraph_indented">He is the one who sent down upon you the book, from it, there are verses that are reinforced with clarity, they are the mother of the book, and others are ambiguous. As for those who have a disease in their hearts, they follow what is unclear from it, seeking the fitna and seeking its interpretation. And no one knows its interpretation except God and those who are well versed in knowledge say, we believe in it; it is all from our Lord, and no one remembers except those who possess intellect. (Qur&apos;ān 5:7)</p>
                <p className="paragraph">The great Ḥanbalī and Aṯarī scholar, Ibn Qudāma, explains this statement of Imam al-Shāfiʽī, in LumʽatuI Iʽtiqād, as such:&nbsp;</p>
                <p className="paragraph_arabic">وما أشكل من ذلك وجب إثباته لفظاً وترك التعرض لمعناه</p>
                <p className="paragraph_indented">That which is ambiguous from them, it is a must to affirm its utterance and abandon digging into its meaning. [9]</p>
                <p className="paragraph">Meaning, that which is ambiguous about the particular word in question, not the implied meaning of the verse, as it would be irrational to deny the implied meaning of an ambiguous verse. The ambiguity is not in the implied meaning of the verses but rather in the literal meaning of the seemingly anthropomorphic words in those verses. This is a crucial distinction that is often neglected. For example, when God (swt) says to Noah (as):</p>
                <p className="paragraph_arabic">وَاصْنَعِ الْفُلْكَ بِأَعْيُنِنَا وَوَحْيِنَا وَلَا تُخَاطِبْنِي فِي الَّذِينَ ظَلَمُوا ۚ إِنَّهُمْ مُغْرَقُونَ</p>
                <p className="paragraph_indented">And build the ship in front of Our a&rsquo;yun and by our revelation&hellip; &nbsp;(Qur&apos;ān 11:37)</p>
                <p className="paragraph">There is no denying that God (swt) implies that Noah (as) will build the ship under His supervision. Therefore, we do understand the implied meaning, but nevertheless, the ambiguity in the literal meaning of a&rsquo;yun is still present. Based on foundation two, we cannot claim that&nbsp;aʽyun&nbsp;means eyes for God (swt) as His reality is nothing like His creation, thus, we are left with either a literal meaning for&nbsp;aʽyun&nbsp;that is unknown to us or a metaphorical meaning. This, the fact that it can have multiple meanings, is what makes the plural word&nbsp;aʽyun&nbsp;ambiguous.</p>
                <p className="paragraph">After this, Imam Ibn Qudāma mentions one of the statements of Imam Aḥmad (ra) to re-enforce his explanation:</p>
                <p className="paragraph_arabic">نؤمن بها، ونصدق بها، لا كيف ولا معنى، ولا نرد شيئاً منها، ونعلم أن ما جاء به الرسول صلى الله عليه وسلم حق، ولا نرد على رسول الله صلى الله عليه وسلم</p>
                <p className="paragraph_indented">We believe in it and we hold it to be true without modality nor meaning and we do not reject any of it. And we know that whatever the prophet (pbuh) came with is the truth and we do not refute the Messenger of God (swt). [9]</p>
                <p className="paragraph">Even though there have been attempts to give a metaphorical meaning to this statement of Imam Aḥmad to avoid its literal meaning (very ironic), all of them have fallen short of providing a convincing and sufficient reason for their avoidance of the literal meaning. There is absolutely no indication in his statement that he means anything other than the negation of the literal meaning known to Arabs. Furthermore, one can easily argue that &ldquo;without meaning&rdquo; is simply an elaboration and emphasis of &ldquo;without modality&rdquo;. Because modality is a necessary trait of our known literal meaning of anthropomorphic attributes. Therefore, negating modality alone is a negation of their known literal meaning. These points are very subtle and we ask God (swt) to make them evident to the reader.</p>
                <p className="paragraph">And this is in line with the other statements of Imam Aḥmad such as what Imam al-Tamīmī (r.a) narrates to us from him:&nbsp;</p>
                <p className="paragraph_arabic">وكان يقول إن لله تعالى يدين وهما صفة له في ذاته ليستا بجارحتين وليستا بمركبتين و لا جسم ولا من جنس الأجسام ولا من جنس المحدود والتركيب ولا الأبعاض والجوارح</p>
                <p className="paragraph_indented">And Imam Aḥmad (ra) used to say that God (swt) has Yadain and they are attributes for Him in His ipseity. They are not limbs and they are not compound nor a body, nor from the genus of bodies, nor from the bounded and compositions, nor from parts and limbs. [10]</p>
                <p className="paragraph">It is clear that Imam Aḥmad does not affirm Yadain for God (swt) with the literal meaning we know as hands (the limb composed of flesh and bone), nor does he reject it by saying it means power and nothing else. But rather he affirms it and remains quiet regarding its unknown literal meaning which is perfectly in line with his previous statement we mentioned. The reader may ask whether it is possible for the same word to have two literal meanings. The answer is yes, as established in foundation one.</p>
                <p className="paragraph">Let us now mention his position regarding the fact that words do have agreed-upon literal meanings as established in foundation two. He states:</p>
                <p className="paragraph_arabic">إن الأسماء مأخوذة بالشريعة واللغة، وأهل اللغة وضعوا هذا الاسم على كل ذي طول وعرض وسمك وتركيب وصورة وتأليف، والله تعالى خارج عن ذلك كله، فلم يجز أن يسمى جسما لخروجه عن معنى الجسمية، ولم يجئ في الشريعة ذلك، فبطل</p>
                <p className="paragraph_indented">Indeed names are taken from divine law and language and the people of language designated this name (body) for everything that has a length, width, height, composition, shape, and formation. And God (swt) is beyond this without exception; thus, it was not made permissible for Him to be called a body since He is beyond the meaning associated with a body. And it also has not come in the Shari&rsquo;a; therefore, it is nullified. [10]</p>
                <p className="paragraph">The above statement is in regards to names that are not in scripture. In the case of names and attributes that have been mentioned in Shari&rsquo;a, the known literal meanings of which are anthropomorphic, Imam Aḥmad negates their known literal meanings, as demonstrated previously, while affirming the attributes themself.&nbsp;</p>
                <p className="paragraph">Last but not least, as stated above, Imam Aḥmad does not reject the unknown literal meaning but rather rejects the anthropomorphic literal meaning that we as human beings associate with these words. Yet, at the same time, he does not entertain what that unknown literal meaning may be. He states:</p>
                <p className="paragraph_arabic">أن لله تعالى وجها لا كالصورة المصورة و الأعيان المخططة بل وجه وصفه بقوله (( كل شيء هالك إلا وجهه )) و من غير معناه (أي المعنى المجهول الذي أراد الله) &nbsp;فقد ألحد عنه.</p>
                <p className="paragraph_indented">God (swt) has a wajh that is not like the shaped images nor lined substances but rather a wajh which He described with His statement ((everything will perish except His wajh)) and whoever changes its (unknown intended) meaning (of His statement or wajh to pleasure or essence for example) they have deviated. [10]</p>
                <p className="paragraph">If the last two pronouns refer to wajh, then it means whoever changes the unknown/intended literal meaning and either gives it our known literal meaning or a purely metaphorical one. This is evident from the fact that he (ra) starts by negating the literal meaning that we associate with a&nbsp;wajh. For Imam Aḥmad, if someone was to ask us to describe the&nbsp;wajh&nbsp;of Allah (swt), we would say &ldquo;everything will perish except His wajh&rdquo;&nbsp;and nothing else.</p>
                <p className="paragraph">With this said, we believe in everything God (swt) has said as intended by Him even if we do not understand the intended meaning. Imam al-Shāfiʽī, Imam Aḥmad, and Ibn Qudāma, may God (swt) be pleased with all of them, are teaching us the meaning of&nbsp;tafwīḍ. It is affirming and leaving the meaning to God (swt) and not the &lsquo;how&rsquo;, since as we established modality is negated not deferred. If&nbsp;tafwīḍ&nbsp;was deferring the &lsquo;how&rsquo; to God, Imam Aḥmad and Ibn-Qudāma would not have negated modality completely.&nbsp;</p>
                <p className="paragraph">One may ask, then how do we understand the statement of Imam Mālik when he says:</p>
                <p className="paragraph_arabic">الاستواء معلوم والكيف مجهول؟</p>
                <p className="paragraph_indented">Istiwā&rsquo;&nbsp;is known and modality is unknown?</p>
                <p className="paragraph">The answer is that the statement of Imam Mālik with the sound&nbsp;sanad&nbsp;ends with:</p>
                <p className="paragraph_arabic">و الكيف عنه مرفوع</p>
                <p className="paragraph_indented">And modality is negated from Him [11]</p>
                <p className="paragraph">And in another sound narration:</p>
                <p className="paragraph_arabic">و الكيف غير معقول</p>
                <p className="paragraph_indented">&nbsp; &nbsp;&nbsp;And modality is irrational [11]</p>
                <p className="paragraph">This narration has been narrated from Sufyān ibn ʽUyayna and Rabīʽa the Shaykh of Imam Mālik according to Imam Ibn Ḥajar. Imam Ibn Ḥajar also provides a narration from our Mother Umm Salama in which she says:&nbsp;</p>
                <p className="paragraph_arabic">الاستواء غير مجهول والكيف غير معقول.</p>
                <p className="paragraph_indented">Istiwā&rsquo; is not unknown and modality is irrational. [11]</p>
                <p className="paragraph">The difference in words does change the meaning, contrary to what some scholars have argued. Something being unknown means it is established and exists; we simply do not know it. For something to be incomprehensible or negated from Him, however, means that its existence is a logical impossibility, meaning modality is impossible for the essence of God as only the essence of creation is described by howness. And in this, as stated before, there is a clear negation of the known literal meaning Arabs use for&nbsp;Istiwā&apos;.&nbsp;</p>
                <p className="paragraph">Furthermore, the first part of the statement does not contradict the statements of Imam al-Shāfiʽī nor Imam Aḥmad as Imam Mālik is simply saying the existence of Istiwā&rsquo; in the Qur&apos;ān is known. He does not say the meaning of Istiwā&rsquo; in the Qur&apos;ān is known (maʽlūm). If that was the case he would have said&nbsp;al-Istiwā&rsquo;&nbsp;mafhūm&nbsp;(Istiwā&rsquo; is understood), or&nbsp;maʽnā al-istiwā&rsquo; maʽlūm&nbsp;(the meaning of Istiwā&rsquo; is known). Anyone who claims that Imam Mālik means the meaning of Istiwā&rsquo; in the Qur&apos;ān is known is taking the metaphorical meaning of what Imam Mālik said (another irony) as considering the&nbsp;muḍāf&nbsp;being implied is a metaphorical (majāzī) interpretation, such as in the verse:</p>
                <p className="paragraph_arabic">وَسْـَٔلِ ٱلْقَرْيَةَ ٱلَّتِى كُنَّا فِيهَا</p>
                <p className="paragraph_indented">Ask the village in which we were</p>
                <p className="paragraph">Meaning ask the people of the village. Arab linguists consider this a type of allegory.&nbsp;</p>
                <p className="paragraph">And last but not least, let&rsquo;s address the statement of Imam al-Aʽzam, Abū Ḥanīfa, when he states:</p>
                <p className="paragraph_arabic">فما ذكره الله في القرآن من ذكر الوجه واليد والنفس فهو له صفة بلا كيف ولا يقال إن يده قدرته أو نعمته لأن فيه إبطال الصفة وهو قول أهل القدر والاعتزال.</p>
                <p className="paragraph_indented">And what God (swt) mentions in the Qur&apos;ān such as the mention of al-Wajh and al-Yad, and al-Nafs they are for Him attributes that do not have modality. And one does not say His yad is His power or His bounties for indeed in it there is the negation of the attribute and that is the way of the people of decree (Qadar) and the people of isolation (i.e. Muʽtazilis) [12]</p>
                <p className="paragraph">There are two points that need to be addressed here. The first is Imam Abū Ḥanīfa&rsquo;s negation of modality, which necessitates the negation of the literal meanings humans use for these attributes. And the second is his affirmation of the unknown literal meaning of these attributes by rejecting those who affirm only a metaphorical meaning for them, such as the&nbsp;Qadarīyyah&nbsp;and&nbsp;Muʽtazila. So he affirms these attributes deferring their unknown literal meaning to God (swt) and negates the known literal meanings used by humans for these attributes by negating modality. And no one explains this better than one of the greatest scholars of both the fiqh of Imam Abū Ḥanīfa and his ʽAqīda, Imam al-Ṭaḥāwī:</p>
                <p className="paragraph_arabic">و من وصف الله بمعنى من معاني البشر فقد كفر، فمن أبصر هذا اعتبر وعن مثل قول الكفار انزجر وعلم أنه بصفاته ليس كالبشر.</p>
                <p className="paragraph_indented">And whoever describes God with one of the meanings of humans then they indeed have become unbelievers&hellip; [13]</p>
                <br/>
                <h4>Do Ashʽarī Scholars Negate the Unknown Literal Meanings of Anthropomorphic Attributes?</h4>
                <br/>
                <p className="paragraph">From the works of Imam al-Ashʽarī himself, it is evident that he affirms all of the attributes being discussed without ascribing any meaning to them. Thus, the Ashʽarī school does not negate the unknown literal meaning of attributes. However, some later Ashʽarī scholars spoke only of the implied meanings while negating the known literal meanings (not the unknown). Imam al-Ḏahabī says regarding Imam an-Nawawī, who adopted both approaches:</p>
                <p className="paragraph_arabic">مذهبه في الصفات السمعية السكوت وامرارها كما جاءت وربما تأول قليلا في شرح مسلم</p>
                <p className="paragraph_indented">His approach when it comes to narrated attributes is silence and reciting them as they came and he also sometimes interprets a bit in the commentary of Imam Muslim. [14]</p>
                <p className="paragraph">It is evident from the works of Imam al-Ġazālī, that later Ashʽarīs would not have any theological and rational objections to affirming the unknown literal meanings of these attributes for God (swt). He states in al-Iqtiṣād fil al-ʿtiqād regarding the negation of direction for God (swt):</p>
                <p className="paragraph_arabic">فإن قال الخصم أنا أريد بكونه بجهة معنى سوى هذا، فلم تنكره؟&nbsp;</p>
                <p className="paragraph_indented">And if the opponent states I mean by direction something other than this (location relative to other physical objects); why do you reject it? [15]</p>
                <p className="paragraph_arabic">فأقول أما لفظك فإنما أنكره من حيث إنه يوهم المفهوم الظاهر منه و هو ما يعقل للجوهر و العرض، وذلك كذب على الله تعالى.</p>
                <p className="paragraph_indented">I say, as for your word choice [&ldquo;direction&rdquo; which is not in the Qur&apos;ān], I do not reject it except for the fact that it makes one understand its apparent meaning and that is that which is comprehended for substance and accident. And that (its apparent and known literal meaning) is a lie upon God (swt) [15]</p>
                <p className="paragraph_arabic">و أما مرادك منه فلست أنكره فإن ما لا أفهمه كيف أنكره؟ و عساك تريد به علمه و قدرته و أنا لا أنكر كونه بجهة على معنى أنه عالم وقادر.</p>
                <p className="paragraph_indented">As for that which you mean by it, I don&rsquo;t reject it, for that which I do not understand how can I reject it? Perhaps you mean by it (direction) His knowledge and His might and I do not deny a direction for Him if it is meant by it that He is knowledgeable and omnipotent. [15]</p>
                <p className="paragraph">What Imam al-Ġazālī says here is similar to what Imam Aḥmad says regarding the impermissibility of using the word body (jism) for God (swt) since not only is it apparently anthropomorphic, but it also has no roots in the Qur&apos;ān and Sunnah. Therefore, if Imam al-Ġazālī does not see a theological problem with the non-anthropomorphic meanings given to words that do not exist in the Qur&apos;ān, it is irrational to argue that he would have a theological problem with unknown literal meanings of the attributes in question. Furthermore, as for their use, they are clearly used by God (swt) in the Qur&apos;ān; thus, the rejection of their use, as well, cannot be attributed to Imam al-Ġazālī. In fact, he makes this clear when he talks about&nbsp;al-Istiwā&rsquo;&nbsp;and&nbsp;al-nuzūl&nbsp;in al-Iqtiṣād fil aI-ʿtiqād.</p>
                <p className="paragraph">We learn from what has been mentioned that if there were any disagreements amongst the scholars of &lsquo;Ahl al-Sunnah wa al-Jamā&apos;a&nbsp;they were in the use of language, not in the meanings that can and cannot be attributed to His exalted essence and attributes. It must be pointed out, here, that the&nbsp;Mujassima&nbsp;and the&nbsp;Muʽtazila&nbsp;do not belong to the Sunni school.&nbsp;</p>
                <p className="paragraph">If the reader asks &ldquo;why did God (swt) allow our scholars to have positions that may seem, to some, at odds?&rdquo; The answer is that they were treating different diseases and confronting different deviant groups in their societies; therefore, the cures they were providing their respective societies were different. Imam Aḥmad had the medicine to cure society from the&nbsp;Muʽtazila&nbsp;as they were the main group he was confronting, while the likes of Imam al-Ġazālī had the medicine to cure society from the&nbsp;Mujassima&nbsp;(anthropomorphists) and the philosophers, as they were the main groups he was confronting. But as explained, they did not have creeds that were at odds.&nbsp;</p>
                <br/>
                <h4>Understanding All Words and Verses of the Qur&apos;ān is Not a Must</h4>
                <br/>
                <p className="paragraph">For some, saying we do not understand certain words in the Qur&apos;ān is problematic. In their eyes, not understanding the meaning of certain words in the Qur&apos;ān defeats the purpose of revelation, as it was revealed in order to be understood. The problem with this understanding is its contradiction with Qur&apos;ānic teachings. God (swt) clearly states in the aforementioned verse, in the chapter of Aāl &apos;Imrān, that there are certain verses the interpretation of which only God (swt) knows. The verse could also be understood to say only God and those who have reached the pinnacle of knowledge understand them. But regardless of how the verse is understood, it still indicates that the vast majority of people will not understand the ambiguous verses. God (swt) uses the plural form of Aya when affirming that there are verses we don&rsquo;t understand. Even if there is only one such verse in the Qur&apos;ān, the argument of this group becomes dismissible, let alone many as God (swt) informs us.&nbsp;</p>
                <p className="paragraph_indented">The second rebuttal would be that not understanding the literal meaning of a verse does not prevent us from understanding its implied meaning based on the context as established in foundation 5. For example, when God (swt) says:</p>
                <p className="paragraph_arabic">إِنَّ ٱلَّذِينَ يُبَايِعُونَكَ إِنَّمَا يُبَايِعُونَ ٱللَّهَ يَدُ ٱللَّهِ فَوْقَ أَيْدِيهِمْ</p>
                <p className="paragraph_indented">Behold, all who pledge their allegiance to thee pledge their allegiance to God: the yad of God is over their hands. (Qur&apos;ān 48:10)</p>
                <p className="paragraph">None of the aforementioned Imams, in fact, no one who is well versed in the Arabic language would disagree that this verse implies that God (swt) is on their side, even if we don&rsquo;t understand what&nbsp;yad&nbsp;means. As Imam Ibn Kaṯīr (ra) states:</p>
                <p className="paragraph_arabic">&quot;يد اللّه فوق أيديهم&quot; أي هو حاضر معهم، يسمع أقوالهم ويرى مكانهم، ويعلم ضمائرهم وظواهرهم، فهو تعالى المبايع بواسطة رسوله</p>
                <p className="paragraph_indented">&ldquo;The yad of God (swt) is above their hands&rdquo; meaning He is present with them, hears what they say, sees their location, and knows their internal and external state. He (swt) is the one whose allegiance is being given through His messenger. [16]</p>
                <p className="paragraph">Even if we don&rsquo;t understand the meaning of yad when ascribed to God (swt) in this verse, there is no denying that we do understand what is implied here. In fact, this implied meaning/metaphor was one with which Arabs were very familiar. Thus, what is not being understood is not in the implied meaning but rather the literal. To make it more clear, imagine God (swt) had used &ldquo;nad&rdquo;, a word that has no meaning, instead of yad in the following verse:</p>
                <p className="paragraph_arabic">قَالَ يَٰٓإِبْلِيسُ مَا مَنَعَكَ أَن تَسْجُدَ لِمَا خَلَقْتُ بِيَدَىَّ ۖ أَسْتَكْبَرْتَ أَمْ كُنتَ مِنَ ٱلْعَالِينَ</p>
                <p className="paragraph_indented">O Iblīs (satan), what prevented you from prostrating for that which I have created with my own Yads, did you see yourself too great to prostrate for Adam (as) or were you from the arrogant. (Qur&apos;ān Saad 75)</p>
                <p className="paragraph">The use of the non-existent word &ldquo;nad&rdquo; instead of &ldquo;yad&rdquo; makes no difference in the implied meaning which is understood through the context.&nbsp;</p>
                <p className="paragraph">Only God (swt) and his chosen ones know whether both the unknown literal and metaphorical meanings of all of these words and verses are intended. The indicators that tell us that God (swt) may have intended both the literal and implied meanings are the statements of the four Imams and the obvious implied and contextualized messages that are being conveyed through them.</p>
                <p className="paragraph">What has been mentioned so far, including both the foundations and conclusions, is simply an explanation of the ʽaqīda (creed) of the salaf to which Imam al-Ashʽarī and his followers adhere. What is different about this paper, however, is that it makes an attempt to point out aspects of ʽaqīda that have not been highlighted before. For example, demonstrating that all scholars essentially had the same belief required us to establish the seven foundations. Through the foundations, the fact that none of the scholars believed in the anthropomorphic meanings of words when used for God became evident. This is the ʽaqīda of the four Imams and the vast majority of the scholars of the Umma, be they the mufassirūn, such as Imam at-Ṭabarī, Imam as-Suyūṭī, Imam al-Qurṭubī, and Imam Ibn Kaṯīr, the muḥaddiṯūn, such as Imam al-Bukhārī, an-Nawawī, Ibn Ḥajar, as-Suyūṭī, al-Bayḥaqī, and al-Ḥākim, or the jurists such as Ibn Qudāma, Shaykh Zakarīyā al-Ansārī, Imam at-Ṭaḥāwī, and Imam an-Nawawī. The dominance of this position, which includes the seven foundations and conclusions, is observed in all schools. However, some who follow the Ḥanbalī school favor the position of Shaykh Ibn Taymīyyah (ra) who, even though, in most cases, reaches the same conclusions, does not follow the foundations of other scholars.&nbsp;</p>
                
                <Link to={"/articles/coherence-of-creed-one"}>
                    <div>
                        <p className="paragraph">The Coherence of Creed - Part One - Foundations</p>
                    </div>
                </Link>
                
                <h6 align="left"><br /><i>By Souhayl Maronesy</i></h6>
                <h6 align="left"><i>11/30/2022</i></h6>
                <h5 align="left"><br/>References:</h5>
                <p className="paragraph"><br/><i>[9] Imam Ibn-Qudāma, Lum’at al-Iʿtiqād, (Beirut: al-Maktab al-Islām: 1995) 4-7</i></p>
                <p className="paragraph"><i>[10] ʿAbd al-Azīz Ibn al-Hāriṯ al-Tamīmī, Iʿtiqād al-Imām al-Munabbil Abi ʿAbd Allāh Aḥmad Ibn Ḥanbal, (Beirut: Dār al-Kutub al-’lmīyyah, 2001) 17, 22, 45</i></p>
                <p className="paragraph"><i>[11] Ibn Ḥajar, Fatḥ al-bārī sharḥ ṣaḥīḥ al-Bukhārī, 17 vols. (Ryadh: Dar Ṭaybah, 2005), 17:396, 17:285</i></p>
                <p className="paragraph"><i>[12] Imam Abū Ḥanīfa, Al-Fiqh al-Akbar, (Egypt: Dār al-Kutub al-ʿArabīyyah al-Kubrā 1909), 35-36</i></p>
                <p className="paragraph"><i>[13] Imam al-Ṭaḥāwī, al-ʽAqīdat al-Ṭaḥāwīyyah, (Beirut: Dār Ibn Ḥazm, 1995), 13</i></p>
                <p className="paragraph"><i>[14] Imam al-Ḏahabī, Tārikh al-Islam, 52 vols. (Beirut: Dār al-Kitāb al-ʿArabī, 1999), 50:256</i></p>
                <p className="paragraph"><i>[15] Imam al-Ġazāli, al-Iqtiṣād fi al-Iʿtiqād, (Beirut: Dār al-Minhāj, 2016), 154-156</i></p>
                <p className="paragraph"><i>[16] Ibn Kaṯīr, Tafsir al-Qurʽān al-ʿAẓim, 8 vols. (Riyadh: Dar Ṭaybah,1999), 7:329</i></p>

            </div>
        )
    }
}
