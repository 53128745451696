import React from 'react'
import {useEffect} from "react"

function Courses() {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Courses"
  }, []);
    
  return (
    <div className='webpage_content'>
      <h4>The Fundamentals Program (18 months)</h4><br/>
      <h5 className='topic_headers'>Aqida - Creed</h5>
      <br/>
      <table className='course_table'>
          <thead>
              <th className='course_table'>Title</th>
              <th id="arabic_header" className='course_table'>عنوان الكتاب</th>
          </thead>
          <tr className='course_table'>
              <td className='course_table'>Al-Maqaasid of Imam al-Nawawi - Creed</td>
              <td id='arabic_row' className='course_table'>المقاصد النووية - العقيدة</td>
          </tr>
          <tr className='course_table'>
              <td className='course_table'>The Nasafi Creed</td>
              <td id='arabic_row' className='course_table'>العقيدة النسفية</td>
          </tr>
      </table>
      <br/>
      <h5 className='topic_headers'>Fiqh - Jurisprudence</h5>
      <br/>
      <table className='course_table'>
          <thead>
              <th className='course_table'>Title</th>
              <th id="arabic_header" className='course_table'>عنوان الكتاب</th>
          </thead>
          <tr className='course_table'>
              <td className='course_table'>Al-Maqaasid of Imam al-Nawawi - Fiqh</td>
              <td id='arabic_row' className='course_table'>المقاصد النووية - الفقه</td>
          </tr>
          <tr className='course_table'>
              <td className='course_table'>Matn ul-Ghaya wa at-Taqreeb (Fath ul-Qareeb)</td>
              <td id='arabic_row' className='course_table'>متن الغاية و التقريب (فتح القريب)</td>
          </tr>
      </table>
      <br/>
      <h5 className='topic_headers'>Tazkiya - Purification</h5>
      <br/>
      <table className='course_table'>
          <thead>
              <th className='course_table'>Title</th>
              <th id="arabic_header" className='course_table'>عنوان الكتاب</th>
          </thead>
          <tr className='course_table'>
              <td className='course_table'>Al-Maqaasid of Imam al-Nawawi - Purification</td>
              <td id='arabic_row' className='course_table'>المقاصد النووية - التزكية</td>
          </tr>
          <tr className='course_table'>
              <td className='course_table'>Sections of Ihyaa’ Uloom al-Deen</td>
              <td id='arabic_row' className='course_table'>احياء علوم الدين -  آفات اللسان</td>
          </tr>
      </table>
      <br/>
      <h5 className='topic_headers'>Nahw - Grammar</h5>
      <br/>
      <table className='course_table'>
          <thead>
              <th className='course_table'>Title</th>
              <th id="arabic_header" className='course_table'>عنوان الكتاب</th>
          </thead>
          <tr className='course_table'>
              <td className='course_table'>Al-Ajiromyya</td>
              <td id='arabic_row' className='course_table'>الآجرومية</td>
          </tr>
          <tr className='course_table'>
              <td className='course_table'>Awamil al-Jorgani</td>
              <td id='arabic_row' className='course_table'>عوامل الجرجاني</td>
          </tr>
      </table>
      <br/>
      <h5 className='topic_headers'>Sarf - Morphology</h5>
      <br/>
      <table className='course_table'>
          <thead>
              <th className='course_table'>Title</th>
              <th id="arabic_header" className='course_table'>عنوان الكتاب</th>
          </thead>
          <tr className='course_table'>
              <td className='course_table'>Examples</td>
              <td id='arabic_row' className='course_table'>الأمثلة</td>
          </tr>
          <tr className='course_table'>
              <td className='course_table'>The formation of verbs</td>
              <td id='arabic_row' className='course_table'>بناء الأفعال</td>
          </tr>
      </table>
      <br/>
      <h5 className='topic_headers'>Hadith - Prophetic Tradition</h5>
      <br/>
      <table className='course_table'>
          <thead>
              <th className='course_table'>Title</th>
              <th id="arabic_header" className='course_table'>عنوان الكتاب</th>
          </thead>
          <tr className='course_table'>
              <td className='course_table'>The Arba'oon of Imam al-Nawawi</td>
              <td id='arabic_row' className='course_table'>الأربعون النووية</td>
          </tr>
      </table>
      <br/>
      <h5 className='topic_headers'>Ilm al-Hasoob - Computer Science</h5>
      <br/>
      <table className='course_table'>
          <thead>
              <th className='course_table'>Title</th>
              <th id="arabic_header" className='course_table'>عنوان الكتاب</th>
          </thead>
          <tr className='course_table'>
              <td className='course_table'>An introduction to Computer Science</td>
              <td id='arabic_row' className='course_table'>مقدمة في علم الحاسوب</td>
          </tr>
      </table>
      <br/>
      <h4>The Intermediate Program (30 months)</h4>
      <br/>
      <h4>The Advanced Program (36 months)</h4>
    </div>
  )
}

export default Courses
