import React, { Component } from 'react'
import {
    Link
  } from "react-router-dom";

import pray_decree_bw from "../assets/pray_decree_bw.png"
import sb_con from "../assets/sb_con.jpg" 
import masjid_nabi from "../assets/MasjidNabi.jpg" 
import crypto from "../assets/crypto.jpg"

export default class Articles extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Articles"
    }

    render() {
        return (
            <div className="webpage_content">
                <Link to={"/articles/consciousness"}>
                    <div>
                        <p>Consciousness, The Closest Proof of God</p>
                        <img className="article_images" src={sb_con} alt="Alkalam Institute" width="50%" height="50%"/>
                    </div>
                </Link>
                <hr/>
                <Link to={"/articles/contradiction"}>
                    <div>
                        <p>Does Prayer Contradict the Decree of God?</p>
                        <img className="article_images" src={pray_decree_bw} alt="Alkalam Institute" width="50%" height="50%"/>
                    </div>
                </Link>
                <hr/>
                <Link to={"/articles/mawlid"}>
                    <div>
                        <p>Mawlid and the concept of Bid’a</p>
                        <img className="article_images" src={masjid_nabi} alt="Alkalam Institute" width="50%" height="50%"/>
                    </div>
                </Link>
                <hr/>
                <Link to={"/articles/cryptocurrency"}>
                    <div>
                        <p>The Legality of Cryptocurrency in Islam and the Permissibility of its Purchase</p>
                        <img className="article_images" src={crypto} alt="Alkalam Institute" width="50%" height="50%"/>
                    </div>
                </Link>
                <br/><br/><br/><br/>
            </div>
        )
    }
}
