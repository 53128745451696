import React from 'react';
import {useEffect} from "react"

function Team(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Courses"
    }, []);
    
    return (
        <div className='webpage_content'> 
            <div className='parent'>
                <div className='child'>
                    <h5><b>Founder and Resident Scholar</b></h5>
                    <h5>Sh. Souhayl Maronesy</h5>
                    <img className="poster" src="../../assets/souhayl_biopic.png" alt="Alkalām Research Institute (ARI)"/> {' '}
                </div>
                <div className='child'>
                    <p className="paragraph">
                        Souhayl Maronesy is a San Diego resident, who was born in Iran to Iraqi Kurdish parents. In 2007, he began studying traditional Islamic sciences under his grandfather Shaykh Husayn Yusuf Maronesy (رحمه الله), who granted him the permission of public speaking (khiṭāba). After the passing of Shaykh Husayn, Souhayl continued his traditional Islamic education, studying under some of the most distinguished scholars of our time. In 2019, he completed the traditional Islamic sciences curriculum taught in the Kurdistan region for the past few centuries. He obtained his main ‘Ijāza ʿAāma (Islamic sciences degree) from Shaykh Zafir Khidhir Sulayman, who received his ‘Ijāza ʿAāma from the late Mufti of Iraq, Shaykh Abdul-Karim Al-Mudarris (رحمه الله). He has also received ‘Ijāza ʿAāmas from Shaykh Mamduh Al-Mizuri (Erbil) and Shaykh Saleh Al-Ghursi (Konya). He has been granted permission to teach all of the Islamic sciences he has studied. These science include tafsīr, hadīṯ, fiqh, taṣawwūf (purification), Islamic theology and philosophy (aqida), Islamic legal theory (usul al-fiqh), Arabic grammar and morphology, lexical semantics and word coinage, rhetoric, logic, and ʿarūḍ (poetry). He currently teaches al-’Ajirromīyya, the commentary of Taṣrīf al-Zanjānī, and Mullā Jāmī. Other scholars he studied under include: Shaykh Tahir al-Baharki, Shaykh Husnu Gecer, and Shaykh Khalid Al-Kharsa Al-Dimashqi. He has also attended the classes of Dr. Hamza al-Bakri and Shaykh Mahmud al-Misry. Shaykh Souhayl holds a Bachelor’s degree in Electrical Engineering from SDSU and a Master’s degree in Computer Science from UIUC. He is a Software Engineer by profession. He writes poetry in Arabic, Persian, English, and Kurdish.
                    </p>
                </div>
            </div>
            <hr />
            <div className='parent'>
                <div className='child'>
                    <h5><b>Board Member</b></h5>
                    <h5>Marouf Ahmed</h5>
                    <img className="poster" src="../../assets/marouf_bio_pic.jpeg" alt="Alkalām Research Institute (ARI)"/> {' '}
                </div>
                <div className='child'>
                    <p className="paragraph">
                        Marouf Ahmed is from Bangladesh and moved to the US with his family in 2001. He obtained his MBA from the University at Buffalo School of Management in NY and his bachelor’s in business administration from Frank G. Zarb School of Business at Hofstra University in NY. Marouf is a seasoned executive with more than two decades of experience in the property and casualty insurance industry developing diverse, high performing teams and driving comprehensive organization-wide strategy and execution. He has a demonstrated track record of initiating and implementing transformational process and policy changes at an organization-wide level, balancing strategy and execution discipline across all facets of the organization to help drive business results. Professionally, Marouf is passionate about building and cultivating relationships, providing strategic people and process consultation, and being a trusted advisor and problem solver to C-suite executives and senior leadership teams.  His most recent areas of expertise span across all aspects of the Talent space, including talent acquisition, learning and leadership development, people analytics, workforce strategy, staffing, organizational development, training, performance management, diversity and inclusion, compensation, employee relations, succession planning, and internal mobility. Marouf has also held leadership roles in areas such as customer experience, sales, technology, business process improvement and reengineering, and project management. Marouf speaks Bangla (native), Urdu, Hindi and English. He is a huge fan of poetry (reading and writing), his favorite poet is Jalaluddin Rumi. Marouf is always checking out different ethnic cuisines when he can and loves to travel around the world with his better half.
                    </p>
                </div>
            </div>
            <hr />
            <div className='parent'>
                <div className='child'>
                    <h5><b>Secretary</b></h5>
                    <h5>Ahmed Abdi</h5>
                    <img className="poster" src="../../assets/abdibiopic.jpg" alt="Alkalām Research Institute (ARI)"/> {' '}
                </div>
                <div className='child'>
                    <p className="paragraph">
                        Ahmed Abdi was born and raised in San Diego to Somali parents. He obtained a Bachelor’s and a Master’s degree in Mechanical Engineering from San Diego State University. He currently works in the field of Aerospace manufacturing, research, and development. In his spare time, Ahmed enjoys staying up to date on technology, baking, and novice level coding and wood working. 
                    </p>
                </div>
            </div>
            <hr/>
            <div className='parent'>
                <div className='child'>
                    <h5><b>Treasurer</b></h5>
                    <h5>Muhammad Omair Tariq</h5>
                    <img className="poster" src="../../assets/omairpic.png" alt="Alkalām Research Institute (ARI)"/> {' '}
                </div>
                <div className='child'>
                    <p className="paragraph">
                        Muhammad Omair Tariq was born and brough up in Pakistan and moved to UK after completing high school. He obtained a Bachelor’s in Mechanical Engineering from University College London and a Bachelor’s in Business Finance from London School of Economics. He then moved to the US and lived in the East Coast for 2 years before moving to the Bay Area where he currently resides. He has 6 years of experiece working at Apple as an iOS and MacOS Software Engineer. He currently works at Bill.com as a staff iOS Engineer. In his spare time, he likes to learn about crypto, web 3.0, and other emerging technologies.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Team;