import React, { Component } from 'react'
import "../App.css"
import {useEffect, useState} from "react"
import Slider from "react-slick"
import pray_decree_bw from "../assets/pray_decree_bw.png"
import sb_con from "../assets/sb_con.jpg" 
import crypto from "../assets/crypto.jpg"
import creed from "../assets/foundations.png"
import creed_two from "../assets/creed_four_imams.png"
import poster from "../assets/alkalam_poster.jpg"
import arabic_class from "../assets/arabic_class_flyer.png"
import poetry_night from "../assets/islamic_poetry_night.png"
import {MdArrowForwardIos, MdArrowBackIos} from "react-icons/md";
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


const pd = "/articles/contradiction"
const conc = "/articles/consciousness"
const cryp = "/articles/cryptocurrency"
const coherence_one = "/articles/coherence-of-creed-one"
const coherence_two = "/articles/coherence-of-creed-two"
const images = [[creed_two, coherence_two, "The Coherence of Creed - Part Two - The ʽAqīda of the Four Imams"],[creed, coherence_one, "The Coherence of Creed - Part One - Foundations"], [crypto, cryp, "The Legality of Cryptocurrency in Islam and the Permissibility of its Purchase"], [pray_decree_bw, pd, "Does Prayer Contradict the Decree of God?"], [sb_con, conc, "Consciousness, The Closest Proof of God"]]


function Home(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Home"
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,  
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };
  
  const onSubmit = async (data) => {
    const { name, email } = data;
    
    console.log('Name: ', name);
    console.log('Email: ', email);

    try {
        const templateParams = {
          name,
          email
        };
        await emailjs.send(
          "service_ovx2omr",
          "template_o1e9d89",
          templateParams,
          "ROfTpHQncM2WGco43"
        );
        reset();
        toastifySuccess();
      } catch (e) {
        console.log(e);
      }
  };

    
    const PrevArrow = ({ onClick }) => {
        return (
          <div className="arrow next" onClick={onClick}>
            <MdArrowForwardIos />
          </div>
        );
      };
    
      const NextArrow = ({ onClick }) => {
        return (
          <div className="arrow prev" onClick={onClick}>
            <MdArrowBackIos />
          </div>
        );
      };
    
      const [imageIndex, setImageIndex] = useState(0);
    
      const settings = {
        className: "center",
        infinite: true,
        lazyLoad: true,
        speed: 2500,
        dots: true,
        slidesToShow: 1,
        slideToScroll: 1,
        centerMode: true,
        centerPadding: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        // nextArrow: <NextArrow />,
        // prevArrow: <PrevArrow />,
        beforeChange: (current, next) => setImageIndex(next),
      };
    
      return (
        <div className="webpage_content">
          <div className='slider_div'>
            <Slider {...settings}>
              {images.map((img, idx) => (
                <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
                      <h5><b><font face = "Trebuchet MS">{img[2]}</font></b></h5>
                      <hr className='article_seperateor'/>
                      <a className='a_tag' href={img[1]}>
                          <img src={img[0]} alt={img[0]} />
                      </a> 
                </div>
              ))}
            </Slider>
          </div>
          {/* <br/><br/><br/><br/>
          <div className='container'>
            <div className='section_wrapper'>
                <div className='left_poster'>
                  <div className="poster_div">
                    <img src={poetry_night} width="100%" height="100%" />
                  </div>
                </div>
                <div className='right_poster'>
                  <div className="poster_div">
                    <img src={arabic_class} width="100%" height="100%" />
                  </div>
                </div>
            </div>
          </div> */}
          <div className='container'>
            <div className='section_wrapper'>
                <div className='left_register_info'>
                  <div className="poster_div">
                    <img src={poster} width="100%" height="100%" />
                  </div>
                </div>
                <div className='right_register_form'>
                    <div>
                      <h6 className='registration_header'>
                        Registration for the Islamic courses offered
                        by Alkalam is now open. The brothers and sisters
                        who are interested in attending our courses are
                        encouraged to register using the form below.
                      </h6>
                      <hr/>
                      <h6 className='registration_header'>
                        Students are responsible only for the cost associated
                        with purchasing the books. The brothers and sisters going 
                        through financial difficulties will be accomodated!
                      </h6>
                    </div>
                    <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                        {/* Row 1 of form */}
                        <div className='row formRow'>
                        <div className='col-12'>
                            <input
                            type='text'
                            name='name'
                            {...register('name', {
                                required: { value: true, message: 'Please enter your name' },
                                maxLength: {
                                value: 30,
                                message: 'Please use 30 characters or less'
                                }
                            })}
                            className='form-control formInput'
                            placeholder='Name'
                            ></input>
                            {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                        </div>
                        </div> 
                        <div className='row formRow'>
                        <div className='col-12'>
                            <input
                            type='email'
                            name='email'
                            {...register('email', {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                            className='form-control formInput'
                            placeholder='Email'
                            ></input>
                            {errors.email && (
                            <span className='errorMessage'>Please enter a valid email address</span>
                            )}
                        </div>
                        </div>
                        <button className='send-btn' type='submit'>
                        REGISTER
                        </button>
                    </form>
                    <ToastContainer/>
                </div>
            </div>
          </div>
        </div>
      );
}

export default Home;
