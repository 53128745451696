import React, { Component } from 'react'

export default class Mawlid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            like: 0,
            dislike: 0
        }
    }

    likeIncrement = () => {
        this.setState({ like: this.state.like + 1})
    }

    dislikeIncrement = () => {
        this.setState({ dislike: this.state.like + 1})
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "Mawlid and the concept of bid'ah"
    }

    render() {
        return (
            <div className="webpage_content">
                <div className="like_dislike">
                    <div className='like'>
                        <input onClick={this.likeIncrement} type="image" src="../../../assets/article_dislike1.png" alt="dislike" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.like}</div> */}
                    <div className='dislike'>
                        <input onClick={this.dislikeIncrement} type="image" src="../../../assets/article_like1.png" alt="like" width="50px" height="50px"/>
                    </div>
                    {/* <div>{this.state.dislike}</div> */}
                </div>
                <h3>Mawlid and the concept of bid'ah</h3>
                <p className="paragraph"><br />When determining whether the remembrance of the Mawlid of the Prophet (pbuh) is permissible or not, we can take the following two approaches:</p>
                <p className="paragraph"><b>Approach one: </b>The first is to defer this matter to the pious scholars of the past. If we do, we will find that there are scholars on both sides. There were those who held the opinion that Mawlid is permissible, such as Imam al-Shami (the teacher of Imam al-Nawawi), Imam Ibn Hajar al-Asqalani (the author of Fathul-Bari, the most read, cited, accepted commentary of Sahih al-Bukhari), Imam Al-Soyoti (considered one of the greatest scholars of tafseer and hadith) and many others. May Allah (swt) be pleased with them. Contrary to popular belief, Al-Sheikh Ibn Taymyya (ra) also did not see anything wrong with a Mawlid which is in accordance to shari'a. He rightfully criticizes and rejects a Mawlid that is considered an Eid, but he praises those who commemorate the Mawlid as simply a gathering. He says:</p>
                <p className="paragraph_arabic">فتعظيم المولد واتخاذه موسماً قد يفعله بعض الناس ويكون له فيه أجر عظيم ؛ لحسن قصده وتعظيمه لرسول الله صلى الله عليه وسلم</p>
                <p className="paragraph"><i>“And glorifying the birth of the Prophet (pbuh) and upholding it in a gathering is verily done by some people and there is great reward in it for them. Because of the great intention of those upholding it and the glorification of the messenger of Allah (saw).”</i></p>
                <p className="paragraph">What reinforces the fact that Ibn Taymyya approved a Mawlid that is not considered an Eid is what his students said about Sultan Muzaffar, who used to hold large gatherings in remembrance of the Prophet’s Mawlid (pbuh). Both Imam Al-Zahabi and Imam Ibn Katheer (may Allah be pleased with them) praised Sultan Muzaffar, specifically for commemorating the Mawlid every year. Anyone who knows Ibn Taymyya and his students and is honest in their discourse would agree that neither Ibn Taymyya nor his students would ever praise the followers of a blameworthy innovation. In fact to deter them, they would do the opposite, as they do in the case of other blameworthy innovations.</p>
                <p className="paragraph">On the other hand, there were also scholars who did not believe Mawlid was permissible, such as Imam al-Shatibi (ra) and others. However, it can be argued that these scholars held the position of Ibn Taymyya. Meaning that they saw it as a blameworthy innovation only under certain conditions.</p>
                <p className="paragraph"><b>Conclusion: </b>When we see such a difference amongst the people of knowledge, we, the commoners, should not take any strong stances and respect our brothers and sisters who follow an opinion other than ours.</p>
                <p className="paragraph"><b>Approach two: </b>The second approach is to take a purely logic based approach and not consider the sayings of the previous scholars regarding Mawlid. If both sides agree to this approach, they should not try to reinforce their positions through what scholars have said regarding Mawlid, but rather they should base their conclusion purely on reason and other Islamic sciences.</p>
                <p className="paragraph">When taking the second approach, it is important to first define Mawlid and explain what the remembrance of Mawlid entails, as the answer provided by this article will be based on the definition of Mawlid presented in it. Linguistically, Mawlid means the time of birth, and in the terminology of those who commemorate Mawlid, it means a time of showing gratitude to Allah (swt) for the greatest gift to the cosmos, Prophet Muhammad (pbuh). Allah (swt) says: “We have not sent you except as a mercy to the worlds”. It is important to realize that in the aforementioned terminology, Mawlid is not limited to a specific day in Rabi’ al-Awwal, as was the case with the linguistic meaning of Mawlid. Those who are against Mawlid tend to confuse the two definitions. That is why we can witness those who commemorate the birth of the Prophet (pbuh) do it throughout the year. But this commemoration does become more evident in the month of Rabi’ al-Awwal, as it is the month in which the Prophet (saw) was born. For example, in Iraqi Kurdistan, especially the city of Erbil, each masjid holds the Mawlid on a different day in the month of Rabi’ al-Awwal and regularly throughout the year. In the Madaris of Kurdistan, a week does not pass by that a Mawlid is not held.</p>
                <p className="paragraph">It is also important to define and explain bid'ah, as the argument against Mawlid is built upon the misunderstanding and misapplication of this concept. Linguistically, bid'ah means anything new. In the terminology of shari'a, there are many definitions for the word bid'ah, which in itself is enough evidence to show bid'ah is not a concept that can be used without discretion. It is also important to note that none of these definitions necessitate the impermissibility of Mawlid. However, it is best to start with it’s basic meaning understood from the ahadith of the Prophet (saw) and build upon it. This way bid'ah becomes more clear to the readers. This definition is derived from the last section of the following hadith of the Prophet (pbuh):</p>
                <p className="paragraph_arabic">أُوصِيكُمْ بتقوى اللهِ، والسمعِ والطاعةِ، وإن كان عبدًا حبشيًّا، فإنَّهُ من يَعِشْ منكم بعدي يرى اختلافًا كثيرًا، فعليكم بسُنَّتي وسُنَّةِ الخلفاءِ الراشدين المهديِّينَ بعدي، عضُّوا عليها بالنواجذِ، وإياكم ومحدثاتِ الأمورِ، فإنَّ كلَّ مُحدثةٍ بدعةٌ، وكلَّ بدعةٍ ضلالةٌ</p>
                <p className="paragraph"><i>“I advise you to have fear of Allah (swt) and to listen and obey, even if your leader is a Habashi slave. For indeed whoever from you is alive after my death will see much discord, therefore it is upon you to follow my sunnah and the sunnah of the rightly guided Caliphs after me. Hold on to it by the wisdom teeth. And stay away from new things for everything new is an innovation and every innovation is misguidance”</i></p>
                <p className="paragraph">When trying to understand what bid'ah means in this hadith, the first question that must be answered is: “What does the Prophet (pbuh) mean by kollo (every)?”. There are three possibilities. The first is that the Prophet (pbuh) is referring to all innovations, which would include cars, airplanes, and computers. The second possibility is that the Prophet (pbuh) is referring to innovations in governance, which is supported by the beginning of the Hadith. And the third possibility is that the Prophet (pbuh) is referring to innovations in religion. It is obvious from the discourse on bid'ah that both sides believe the Prophet (pbuh) meant all innovations in religion. The only difference is that one side restricts bid'ah further based on another Hadith of the Prophet and the other does not. This will become more clear further into the article. It is important to realize that we are restricting and specifying the unrestricted word kollo (every) to innovations in religion when in fact there is no mokhasis (specifiers) in the Hadith. The intellect, which is considered as one of the detached specifiers in the Principles of Jurisprudence, does not help in this case as all innovations being a misguidance is not an impossibility. Unlike Allah (swt) creating himself in the verse “Allah is the creator of everything”, since in this verse the intellect deems it impossible for “everything” to include Allah (swt) as well. Thus, restricting kollo (every) to all things other than Allah (swt). Then what will enable us to learn what the Prophet (pbuh) meant by “every”? We find that the only solution we have is to turn to the Hadith of our mother Aisha, even though it does not have any explicit references to “every innovation”. This hadith will be explained later in the article.</p>
                <p className="paragraph">The second question we must answer is: “What does the Prophet (pbuh) mean by innovation in religion?”. Innovation in religion could mean using microphones to give sermons and using the internet to spread the message of Islam. Innovation in religion could also mean doing something that is from the religion of Islam (has a foundation in the shari'a of the Prophet (saw)), but the Prophet (pbuh) did not do it in the specific manner, time, and place that is being done. To be clear, we are referring to unrestricted acts of worship, such as praying in South America, making a good dua that the Prophet (pbuh) did not make, Bilal (ra) making a habit for himself to pray two rak’as after every wudhu, and Imam Umar praying taraweeh in congregation throughout the month of Ramadan. Lastly, it could also mean adding something to the religion of Islam that has no relegious foundations to rely upon.</p>
                <p className="paragraph">To claim by innovation the Prophet (pbuh) meant the first two, not only would it be irrational, but it would also go against the actions of the salaf (the first three generations). It is only logical to agree that by innovation the Prophet (pbuh) meant the third type, that which has absolutely no basis in Islam, such as adding a 6th pillars to the pillars of Islam, adding a 6th prayer to the mandatory prayers, adding a 3rd Eid, or making something that is permissible impremissible and viceversa. This is exactly what we take away from the hadith of our mother Aisha (ra). As we stated earlier the only place from which we can derive the intended meaning of innovation is this famous hadith. Our mother Aisha (ra) says:</p>
                <p className="paragraph_arabic">قَالَ رَسُولُ اللَّهِ صلى الله عليه و سلم مَنْ أَحْدَثَ فِي أَمْرِنَا هَذَا مَا لَيْسَ مِنْهُ فَهُوَ رَدٌّ - رَوَاهُ الْبُخَارِيُّ وَمُسْلِمٌ </p>
                <p className="paragraph"><i>“Whoever introduces in this religion of ours that which is not from it, it is rejected”</i></p>
                <p className="paragraph">Notice the precise wording of the Prophet (pbuh)? He (saw) uses the particle min (from) which has the default meaning of Ibtidah (origin). With this linguistic meaning in mind, the meaning of the hadith becomes:</p>
                <p className="paragraph"><i>“Whoever introduces in this religion of ours that which is not originated from it, it is rejected”</i></p>
                <p className="paragraph">This indicates that if an action is based on certain actions or statements of the Prophet (pbuh), even if it is not the exact action taken by the Prophet (pbuh), it is not rejected, since it originates from the shari'a of the Prophet (pbuh). Again, it is important to keep in mind that if the Shari’a has put clear restrictions on a particular act of worship, such as the mandatory prayers, adding to this act of worship would not be considered from the Sharia, as it goes against the restriction. The taraweeh in congregation for all 29 or 30 days of Ramadan is such innovation that originates from the sunnah and the compilation of Hadith is another. This is why great scholars, such as Imam Al-Shafei, have said:</p>
                <p className="paragraph_arabic">قال الشافعي " البدعة بدعتان : محمودة ومذمومة ، فما وافق السنة فهو محمود وما خالفها فهو مذموم " أخرجه أبو نعيم بمعناه من طريق إبراهيم بن الجنيد عن الشافعي</p>
                <p className="paragraph"><i>“Innovation is of two types, praiseworthy innovation and blameworthy innovation. Whatever is in agreement with the sunnah of the Prophet (saw) is praiseworthy and whatever is defies the sunnah of the Prophet is blameworthy”</i> (Fath al-Bari)</p>
                <p className="paragraph">Imam al-Shafei has also said:</p>
                <p className="paragraph_arabic">وجاء عن الشافعي أيضا ما أخرجه البيهقي في مناقبه قال - المحدثات ضربان ما أحدث يخالف كتابا أو سنة أو أثرا أو إجماعا فهذه بدعة الضلال ، وما أحدث من الخير لا يخالف شيئا من ذلك فهذه محدثة غير مذمومة </p>
                <p className="paragraph"><i>“New things in religion are of two types. The newly introduced that is against the Quran, sunnah, athar, or consensus, then it is a misguided innovation. The newly introduced which from the good deeds that is not in disagreement with the aforementioned is not a blameworthy innovation”</i>(Al-Bayhaqi)</p>
                <p className="paragraph">Thus, we see here that Imam al-Shafei, the pioneer of the school of Hadith, is clearly telling us that what the Prophet (pbuh) is talking about in the hadith of bid'ah is the third meaning of Bida’a we explained. The first two meanings, even though they would be considered bid'ah in a sense, are not in the terminology of shari'a, since in it, all bid'ahs are a misguidance. Additionally the first two types of innovations explained are in accordance with the Quran and Sunnah.</p>
                <h4>Addressing the claim that Mawlid is a blameworthy innovation</h4>
                <p className="paragraph"><br/>With the above preface in mind, the underlying concern of those who state that remembering and showing joy for the Mawlid is forbidden is that it is an innovation. Here are their premises:</p>
                <p className="paragraph">
                    <i>
                        <ol>
                            <li>Premise 1: Mawlid is an innovation</li>
                            <li>Premise 2: Every innovation is forbidden</li>
                            <li>Conclusion: Therefore Mawlid is forbidden</li>
                        </ol>
                    </i>
                </p>
                <p className="paragraph">Keeping the meaning of Mawlid and bid'ah explained above in mind, we can say with certainty that the first premise is false. We know from sound ahadeeth that the Prophet (saw) used to show gratitude to Allah (swt) for his birth on Mondays, as that was the day in which he was born. The Sahabi Abi Qatada narrates for us that:</p>
                <p className="paragraph_arabic">أنّ رسول الله (صلّى الله عليه وسلّم) سُئل عن صوم الإثنين فقال فيه ولدتُ وفيه أُنْزِل عليَّ - رواه مسلم</p>
                <p className="paragraph"><i>“The Prophet (pbuh) was asked about fasting mondays and he replied: In it I was borth and in it I received revelation”</i></p>
                <p className="paragraph">This also refutes the claim that we are imitating the people of the book when remembering the birth of the Prophet (pbuh) and showing gratitude for it. Remember, to show gratitude and speak of the blessing of Allah (swt) is a commandment of Allah (swt), and the Prophet (pbuh) teaches this to us by example. Allah (swt) says:</p>
                <p className="paragraph_arabic">وَأَمَّا بِنِعْمَةِ رَبِّكَ فَحَدِّثْ</p>
                <p className="paragraph"><i>“When it comes to the bounties of your look, talk about them”</i></p>
                <p className="paragraph">If it is claimed that the above only indicates the permissibility of showing gratitude for the birth of the Prophet (pbuh) on Mondays and only by fasting, the burden of proof falls on the shoulders of those who claim this. There are no verses nor any ahadith that state remembering the mawlid of the Prophet (pbuh) and showing gratitude for it can only be done on Mondays and it can only be done by fasting. It is highly recommended not to go this route as not only making something that is mubah (permissible) haram an innovation, it is also Kofr (disbelief). Shokr (gratitude) is an act of all body parts, including the tongue. Moreover, the provided verse from the Quran commands us to speak of the bounties of God, which is done through the tongue. Therefore, not only is there nothing against showing gratitude for the Mawlid of the Prophet (pbuh) in the Quran, but there is evidence that it can be done with an action other than fasting and at any time. The science of The Principles of Jurisprudence would highly be recommended for those who do not understand this.</p>    
                <p className="paragraph">This is also a definite refutation of those who say Mawlid is an imitation of the people of the book. Those who continue to make this claim even after knowing the facts above, try to prove their claim using pathos, as logos does not work in their favor. We all know that imitating the people of the book, in their religious rituals, is forbidden. That is why claiming (even if falsely) that Mawlid is a tradition which Muslims have imitated, has a strong emotional impact on the masses and causes those who do not assess this claim with reason to accept it at face value.</p>
                <p className="paragraph">If we agree with the first premise, based on the fact that, in a sense, the current form of remembering the Mawlid is new, the falsity would transition to the second premise. Since not everything that is new in religions, in a sense, is a misguidance. We explained this through reason, the hadith of our mother Aisha, and the statements of Imam al-Shafei. Mawlid undeniably has a basis in the shari'a of the Prophet (pbuh), thus, it is not a blameworthy innovation.</p>
                <p className="paragraph">There is another claim that Mawlid is forbidden because it has become an Eid for those who celebrate it. The reason that is given for this argument is that anything that is observed yearly is an Eid. This is not a valid argument as whoever looks into the Islamic calendar will find that there are numerous occasions which are commemorated yearly that are not Eid. For example Lailatul Qadr is observed every year and in it people worship to draw nearer to Allah (swt) unlike any other night, yet it is common knowledge that it is not Eid. Another very clear example is the day of Aashura in which we fast to show our gratitude to Allah (swt) for saving Musa (pbuh) and his people from the pharaoh. It is a day of joy which repeats every year, however, all Muslims know it is not an Eid.</p>
                <p className="paragraph">There are also those who claim that these “bid'ahs” (we don’t agree with them being Bida’s in terminology) have caused the misery that the ummah is going through. Well, to that we say wrong again. At the peak of the Islamic civilization, when Salahadeen had once again liberated Jerusalem, Mawlid was being commemorated by him and those close to him, such as sultan Muzaffar of Erbil. And at the peak of the ottoman empire Mawlid was commemorated throughout the year. In fact if we want to play the blame game, we can argue it was to the contrary, it was only after the collapse of the Ottoman empire and the spreading of Takfeeri ideology, which promotes great animosity towards Mawlid, that our miseries began. But let’s not play this foolish game, as I don’t think Mawlid or lack thereof is the cause of any of our miseries.</p>
                <p className="paragraph">I believe this should suffice to put an end to this argument that takes place yearly between Muslims. If it does not, rest assured that the force which does not allow it to end is satanic. Let us respect all of our scholars, both those who see nothing wrong with Mawlid and those who see it as impermissible. This reverence should in turn allow us to also respect the followers of both groups of scholars.</p>
                <p className="paragraph_arabic"></p>
                <p className="paragraph"><i></i></p>
                <br/><br/><br/>
            </div>
        )
    }
}
