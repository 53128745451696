import React from 'react';
import {useEffect} from "react"

function Donate(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Donate"
    }, []);
    
    return (
        <div className="webpage_content">
            <h5>All donations will go towards realizing the mission of
                Alkalam Institute, such as securing a facility, operations, courses,
                seminars, publications, and research. To learn more about our mission, please visite the <a href='https://alkalam.org/about'>about</a> page.
            </h5>
            <br/>
            <h4>Donate with Stripe (2.9% transaction fee)</h4>
            <br />
            <a href='https://donate.stripe.com/00g6qUdUV4aD51KcMM'>
                <button className='stripe-donate-btn' id="donate">Donate</button>
            </a>
            <br />
            <br />
            <br/>
            <h4>Donate with Zelle (no transaction fee, preferred)</h4>
            <br />
            <h5>Alkalam's Zelle email address:</h5>
            <h5><b>donations@alkalam.org</b></h5>
            <br />
            <h5>Alkalam's Zelle QR code:</h5>
            <img src="../../assets/zelleqrcode.jpg" width="50%" height="50%"/>

        </div>
    );
}

export default Donate;