
import React, { Component } from 'react'
import {NavLink, Navbar, Nav, NavbarBrand} from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import About from './About';
import Articles from './Articles';
import Home from './Home';
import Media from './Media';
import Contact from './Contact.js';
import Initiatives from './Initiatives.js';
import Donate from './Donate';
import Consciousness from './articles/Consciousness';
import Mawlid from './articles/Mawlid';
import Contradiction from './articles/Contradiction';
import Cryptocurrency from './articles/Cryptocurrency';
import CoherenceOfCreedOne from './articles/CoherenceOfCreedOne'
import { RiFacebookBoxLine, RiYoutubeLine } from "react-icons/ri";
import { FaAlignCenter } from 'react-icons/fa';
import Courses from './Courses';
import { HelmetProvider } from 'react-helmet-async';
import CoherenceOfCreedTwo from './articles/CoherenceOfCreedTwo';
import Team from './Team';

const NavbarComp = () => {
        const {
            register,
            handleSubmit,
            reset,
            formState: { errors }
          } = useForm();
          
        const onSubmit = async (data) => {
            const { email } = data;
        
            console.log('Email: ', email);

            try {
                const templateParams = {
                    email,
                };
                await emailjs.send(
                    "service_ovx2omr",
                    "template_i0h02a6",
                    templateParams,
                    "ROfTpHQncM2WGco43"
                );
                reset();
                } catch (e) {
                console.log(e);
                }
        };

        return (
            <>
            <div id='page-container'>
                <div id='content-wrap'>
                    <Router>
                        <>
                        <div className='header'>
                            <Navbar className='navbar-1' bg="dark">
                                {/* <div className='navbar_1_banner_left'>
                                    <Container>
                                        <Navbar.Brand>
                                            <img src="../../assets/alkalam_banner_1.png" alt="Alkalam Institute (ARI)" width="191px" height="63px"/> {' '}
                                        </Navbar.Brand>
                                    </Container>
                                </div> */}
                                <div className='ms-auto'>
                                    <Container>
                                        <Navbar.Brand>
                                            <img src="../../assets/bismillah_code.png" alt="Alkalam Institute" width="120px" height="40px"/> {' '}
                                        </Navbar.Brand>
                                    </Container>
                                </div>
                            </Navbar>
                            <div className='navbar-separator'></div>
                            <Navbar className='navbar-3' bg="myGrey" variant={"light"} expand="sm" 
                                        collapseOnSelect>
                                <Container className='container-3'>
                                    <Navbar.Brand>
                                        <img src="../../assets/LogoTransparent1.png" alt="Alkalam Institute" width="90px" height="90px"/> {' '}
                                        {/* <a href="https://www.freepnglogos.com/pics/allah" title="Image from freepnglogos.com"><img src="https://www.freepnglogos.com/uploads/allah-png/file-allah-logo-svg-wikipedia-2.png" width="35px" height="35px" alt="file allah logo svg wikipedia" /></a> */}
                                    </Navbar.Brand>
                                    <Navbar.Toggle aria-controls="navbarScroll" />
                                    <Navbar.Collapse id="navbarScroll">
                                        <Nav
                                        className="justify-content-center"
                                        // style={{ maxHeight: '100px'}}
                                        style={{ flex: 1}}
                                        navbarScroll
                                        >
                                            <NavLink activeClassName="active" className="home" eventKey="1" as={Link} to={"/home"}>Home</NavLink>
                                            <NavLink activeClassName="active" className="about" eventKey="2" as={Link} to={"/about"}>About</NavLink>
                                            <NavLink activeClassName="active" className="team" eventKey="3" as={Link} to={"/team"}>Team</NavLink>
                                            <NavLink activeClassName="active" className="articles" eventKey="4" as={Link} to={"/articles"}>Articles</NavLink>
                                            <NavLink activeClassName="active" className="media" eventKey="5" as={Link} to={"/media"}>Media</NavLink>
                                            <NavLink activeClassName="active" className="courses" eventKey="6" as={Link} to={"/courses"}>Courses</NavLink>
                                            <NavLink activeClassName="active" className="contact" eventKey="7" as={Link} to={"/contact"}>Contact</NavLink>
                                            <NavLink activeClassName="active" className="initiatives" eventKey="8" as={Link} to={"/initiatives"}>Initiatives</NavLink>
                                            <NavLink activeClassName="active" className="donate" eventKey="9" as={Link} to={"/donate"}>Donate</NavLink>
                                        </Nav>
                                        {/* <Nav
                                        className="ml-auto my-2 my-lg-0" Initiatives
                                        style={{ maxHeight: '100px' }}
                                        navbarScroll
                                        >
                                            <Nav.Link eventKey="4" as={Link} to={"/subscribe"}>Subscribe</Nav.Link> */}
                                            {/* <NavDropdown title="Link" id="navbarScrollingDropdown">
                                                <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                                                <NavDropdown.Item href="#action4">Another action</NavDropdown.Item>
                                                <NavDropdown.Divider />
                                                <NavDropdown.Item href="#action5">Something else here</NavDropdown.Item>
                                            </NavDropdown> */}
                                        {/* </Nav> */}
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        </div>
                        <div className="icons_social">
                            <a href="https://youtu.be/JeQnFF1_xKM">
                                <RiYoutubeLine size="2.4em" color="darkRed"/>
                            </a>
                            < a href="https://www.facebook.com/profile.php?id=100077793745926"> 
                                <RiFacebookBoxLine size="2.0em" color="darkBlue"/>
                            </a>
                        </div>
                        </>

                        {/* <div id="banner"> */}
                            {/* <div> */}
                                {/* <div>
                                    <img src="../../assets/LogoTransparent.png" width="80px" height="80px"/>
                                </div> */}
                                {/* <h3 class="head"> Welcome to Alkalam Institute </h3> */}
                            {/* </div> */}
                        {/* </div> */}
                        <div>
                            <Switch>
                                <Route path="/articles/coherence-of-creed-two">
                                    <HelmetProvider>
                                        <CoherenceOfCreedTwo />
                                    </HelmetProvider>
                                </Route>
                                <Route path="/articles/coherence-of-creed-one">
                                    <HelmetProvider>
                                        <CoherenceOfCreedOne />
                                    </HelmetProvider>
                                </Route>
                                <Route path="/articles/cryptocurrency">
                                    <Cryptocurrency />
                                </Route>
                                <Route path="/articles/consciousness">
                                    <Consciousness />
                                </Route>
                                <Route path="/articles/mawlid">
                                    <Mawlid />
                                </Route>
                                <Route path="/articles/contradiction">
                                    <Contradiction />
                                </Route>
                                <Route path="/team">
                                    <Team />
                                </Route>
                                <Route path="/about">
                                    <About />
                                </Route>
                                <Route path="/articles">
                                    <Articles />
                                </Route>
                                <Route path="/media">
                                    <Media/>
                                </Route>
                                <Route path="/courses">
                                    <Courses />
                                </Route>
                                <Route path="/contact">
                                    <Contact />
                                </Route>
                                <Route path="/initiatives">
                                    <Initiatives />
                                    </Route>
                                <Route path="/donate">
                                    <Donate />
                                </Route>
                                <Route path="/">
                                    <Home />
                                </Route>
                            </Switch>                    
                        </div>
                    </Router>
                </div>
                <footer id='footer'>
                    <div className='footer_top'>
                        <div className='footer_left'>
                            <div className='footer_newsletter'>
                                <div className='fixe_newsletter'>Subscribe to Alkalām Newsletter</div>
                            </div>
                            <form className='footer_subscribe' onSubmit={handleSubmit(onSubmit)} noValidate>
                                <div className='footer_form'>
                                    <div className='email_form'>
                                        <input id="sub_email_form_id"
                                        type='email'
                                        name='email'
                                        {...register('email', {
                                            required: true,
                                            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                        })}
                                        className='form-control formInput'
                                        placeholder='Your email'
                                        ></input>
                                        {errors.email && (
                                        <span className='errorMessage'>Email not valid</span>
                                        )}
                                    </div>
                                </div>
                                <div className='footer_button'>                    
                                    <button className='submit-btn' type='submit'>
                                        <b>Subscribe</b>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className='footer_right'>
                            <div className='footer_right_div'>Alkalām Institute</div>
                            <div className='footer_right_div'>San Diego, CA</div>
                            <div className='footer_right_div'>info@alkalam.org</div>      
                        </div>
                    </div>
                    <div className='footer_bottom'>© 2024 Alkalām Institute. EIN: 88-3837127. Alkalam is a 501(C)(3) organization. All donations are tax deductible.</div>
                </footer>
            </div>
            </>
        )
    }

export default NavbarComp