import React from 'react'
import PropTypes from "prop-types";
import {useEffect} from "react"

const YoutubeEmbedFatiha = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${embedId}`}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen>
    </iframe>
  </div>
);

const YoutubeEmbedIkhlas = ({ embedId }) => (
  <div className="video-responsive">
    <iframe 
        width="372" 
        height="210" 
        src={`https://www.youtube.com/embed/${embedId}`}
        title="The 10 Principles of Tafseer" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
  </div>
);

const YoutubeEmbedAdamHawa = ({ embedId }) => (
  <div className="video-responsive">
    <iframe 
        width="372" 
        height="210" 
        src={`https://www.youtube.com/embed/${embedId}`}
        title="The 10 Principles of Tafseer" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
  </div>
);

const YoutubeEmbedAjirromiyya = ({ embedId }) => (
  <div className="video-responsive">
    <iframe 
        width="372" 
        height="210" 
        src={`https://www.youtube.com/embed/${embedId}`}
        title="The 10 Principles of Tafseer" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
  </div>
);

const YoutubeEmbedKhutbas = ({ embedId }) => (
  <div className="video-responsive">
    <iframe 
        width="372" 
        height="210" 
        src={`https://www.youtube.com/embed/${embedId}`}
        title="The 10 Principles of Tafseer" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
  </div>
);

const YoutubeEmbedTongue = ({ embedId }) => (
  <div className="video-responsive">
    <iframe 
        width="372" 
        height="210" 
        src={`https://www.youtube.com/embed/${embedId}`}
        title="The 10 Principles of Tafseer" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
  </div>
);

const YoutubeEmbedBunyaan = ({ embedId }) => (
  <div className="video-responsive">
    <iframe 
        width="372" 
        height="210" 
        src={`https://www.youtube.com/embed/${embedId}`}
        title="The 10 Principles of Tafseer" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
    </iframe>
  </div>
);

YoutubeEmbedIkhlas.propTypes = {
  embedId: PropTypes.string.isRequired
};


function Media() {

  useEffect(() => {
      window.scrollTo(0, 0);
      document.title = "Media"
  }, []);

  return (
    <div className='webpage_content'>
      <h3><i>Awakening</i></h3>
      <hr/>
      <div className='parent'>
        <div className='child-media'>
              <YoutubeEmbedKhutbas embedId="videoseries?list=PL_MpD9b-5NAWJ1b1s4tfu-KS0ZBS2Z5ps" /> 
        </div>
        <div className='child-media'>
            <YoutubeEmbedTongue embedId="videoseries?list=PL_MpD9b-5NAVvYAy6jHRQKlXclF8eQkyk" /> 
        </div>
      </div>
      <hr/>
      <br /><h3><i>Empowerment</i></h3>
      <hr/>
      <div className='parent'>
          <div className='child-media'>
              <YoutubeEmbedFatiha embedId="videoseries?list=PL_MpD9b-5NAXvfyI2V48ocilVa15VHbzd" /> 
        </div>
        <div className='child-media'>
            <YoutubeEmbedIkhlas embedId="videoseries?list=PL_MpD9b-5NAVJ_6JgQK2clF1X1GjaiGlO" /> 
        </div>
      </div>
      <br/>
      <div className='parent'>
        <div className='child-media'>
            <YoutubeEmbedAdamHawa embedId="videoseries?list=PL_MpD9b-5NAVPGmQTvGOCK8WVJabKOoXg" /> 
        </div>
        <div className='child-media'>
              <YoutubeEmbedAjirromiyya embedId="videoseries?list=PL_MpD9b-5NAUJ9yyGyRiETiBv5WS_RdxI" /> 
        </div>
      </div>
      <hr/>
      <br /><h3><i>Migration</i></h3>
      <hr />
      <div className='parent'>
        <div className='child-media'>
            <YoutubeEmbedBunyaan embedId="videoseries?list=PL_MpD9b-5NAWt-ilPiwcU3zgjGP_RbwUK" /> 
        </div>
      </div>
    </div>
  )
}

export default Media;