// require('dotenv').config()

import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';
import ContactInfoItem from './ContactInfoItem';
import { MdLocalPhone, MdEmail } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,  
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };
  
  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;
    
    console.log('Name: ', name);
    console.log('Email: ', email);
    console.log('Subject: ', subject);
    console.log('Message: ', message);

    try {
        const templateParams = {
          name,
          email,
          subject,
          message
        };
        await emailjs.send(
          "service_ovx2omr",
          "template_bkwzob1",
          templateParams,
          "ROfTpHQncM2WGco43"
        );
        reset();
        toastifySuccess();
      } catch (e) {
        console.log(e);
      }
  };

  return (
    <div className='webpage_content'>
      <div className='contact_message'>
        <h5>Please contact us with any inquires or comments!</h5>
      </div>
      <div className='container'>
        <div className='contactSection__wrapper'>
            <div className='left_contact_info'>
                <ContactInfoItem
                    icon={<MdLocalPhone size="1.5em"/>}
                    text="+1 619 416 9878"/>
                <ContactInfoItem
                    icon={<MdEmail size="1.5em"/>}
                    text="info@alkalam.org"/>
                <ContactInfoItem
                    text="San Diego, CA"/>
            </div>
            <div className='right_contact_form'>
                <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                    {/* Row 1 of form */}
                    <div className='row formRow'>
                    <div className='col-12'>
                        <input
                        type='text'
                        name='name'
                        {...register('name', {
                            required: { value: true, message: 'Please enter your name' },
                            maxLength: {
                            value: 30,
                            message: 'Please use 30 characters or less'
                            }
                        })}
                        className='form-control formInput'
                        placeholder='Name'
                        ></input>
                        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                    </div>
                    </div> 
                    <div className='row formRow'>
                    <div className='col-12'>
                        <input
                        type='email'
                        name='email'
                        {...register('email', {
                            required: true,
                            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                        })}
                        className='form-control formInput'
                        placeholder='Email'
                        ></input>
                        {errors.email && (
                        <span className='errorMessage'>Please enter a valid email address</span>
                        )}
                    </div>
                    </div>
                    {/* Row 2 of form */}
                    <div className='row formRow'>
                    <div className='col-12'>
                        <input
                        type='text'
                        name='subject'
                        {...register('subject', {
                            required: { value: true, message: 'Please enter a subject' },
                            maxLength: {
                            value: 75,
                            message: 'Subject cannot exceed 75 characters'
                            }
                        })}
                        className='form-control formInput'
                        placeholder='Subject'
                        ></input>
                        {errors.subject && (
                        <span className='errorMessage'>{errors.subject.message}</span>
                        )}
                    </div>
                    </div>
                    {/* Row 3 of form */}
                    <div className='row formRow'>
                    <div className='col-12'>
                        <textarea
                        rows={7}
                        name='message'
                        {...register('message', {
                            required: true
                        })}
                        className='form-control formInput'
                        placeholder='Message'
                        ></textarea>
                        {errors.message && <span className='errorMessage'>Please enter a message</span>}
                    </div>
                    </div>
                    <button className='send-btn' type='submit'>
                    SEND
                    </button>
                </form>
                <ToastContainer/>
            </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;