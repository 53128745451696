import React from 'react';
import {useEffect} from "react"

function Initiatives(props) {

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Donate"
    }, []);

    return (
        <div className='webpage_content'>
            <h5>Developers on the Path - An open source initiative </h5> 
            <h6><b>Collaborating for a divine cause</b></h6>
            <h6>Details coming soon</h6>
        </div>
    );
}

export default Initiatives;